export default {
  changeLang: 'Changement de langue',
  back: 'Retour',
  header: {
    profile: 'Mon profil',
    homeLink: 'Accueil',
    authentication: 'Authentification',
    myServicesLink: 'Mes démarches',
    onlineServicesLink: 'Services en ligne',
    appointmentsLink: 'Mes rendez-vous',
    documentsLink: 'Mon porte-documents',
    logout: 'Me déconnecter',
    navigationLabel: 'Navigation',
    publicServiceIndividuals: 'Service Public Particuliers',
    publicServiceBusinesses: 'Service Public Entreprises',
    individuals: 'Particuliers',
    businesses: 'Entreprises',
    searchPlaceholder: 'Rechercher un service en ligne',
    documentSearchPlaceholder: 'Rechercher un document',
    popup: {
      mainText:
        'Pour accéder à l’espace {userTypePopup}, vous allez être déconnecté(e). <br/> Vous pourrez ensuite vous connecter à l’aide de vos identifiants {userTypePopup} (ou créer un compte).',
      logout: 'Vous déconnecter ?\n',
      wouldYouLikeToLogout: 'Souhaitez-vous vous déconnecter ?\n',
    },
    accessMonGuichet: 'Aller à MonGuichet.mc {userTypePopup}',
    partnersLabel: 'Consulter tous les sites liés',
    partnersCloseButtonActionLabel: 'Fermeture',
    partnersActionLabel: 'OuvertureSitesLiés',
    partnersLink: 'https://www.gouv.mc/Action-Gouvernementale/Sites-lies',
    infosLabel:
      'Toutes les informations et services utiles pour les usagers de la Principauté sur les sites officiels du Gouvernement Princier de Monaco',
    certifiedWebsiteLabel: 'Site officiel de la Principauté de Monaco',
    userAccountLabel: 'Compte : ',
    metanav: {
      governmentSiteHref: 'https://www.gouv.mc/',
      governmentSiteLabel:
        'Le site institutionnel du Gouvernement Princier de Monaco',
      monGuichetHref: 'https://monguichet.mc/',
      monGuichetLabel: 'Le portail pour réaliser vos démarches en ligne',
      myPublicServiceHref: 'https://monservicepublic.gouv.mc',
      myPublicServiceLabel:
        'Toutes les démarches et informations pour les particuliers',
      myCompanyServiceHref: 'https://monentreprise.gouv.mc',
      myCompanyServiceLabel:
        'Toutes les démarches et informations pour les entreprises',
    },
  },
  footer: {
    legal: 'Mentions légales',
    cookies: 'Cookies',
    contact: 'Contact',
    tou: 'CGU',
    copyright:
      'Tous droits réservés - Monaco {prodYear} - {currentYear} - {appVersion}',
    socialNetwork: 'Suivez-nous sur les réseaux sociaux',
    partnersLabel:
      'Retrouvez tous les sites partenaires liés au gouvernement sur ce lien',
    partnersLink: 'https://www.google.fr/',
    governmentLink: 'https://www.gouv.mc/',
    infosLabel:
      'Retrouvez toutes les informations et les services sur les sites du Gouvernement Princier de Monaco',
  },
  login: {
    login: 'Me connecter',
  },
  signup: {
    pageTitle: 'Créer votre compte',
    titleBusinesses: 'Créer votre compte Entreprises',
    backToAccountChoice: 'Retour',
    titleIndividuals: 'Créer votre compte Particuliers',
    address: 'Mon adresse',
    identifyWithMconnect: 'Je m’identifie avec MConnect',
    loginWithMconnect: 'M’identifier avec MConnect',
    chooseUsernames: 'Je choisis mes identifiants',
    usernamePlaceholder: 'Ex : johndoe06',
    alreadyHaveAnAccount: 'J’ai déjà un compte ?',
    haveVisa: 'Je suis titulaire d’une carte de Séjour',
    pleaseAcceptTerms:
      'Pour pouvoir utiliser ce service, merci de valider les <a href="{page}" target="blank" class="text-interaction">Conditions d’utilisation</a>.',
    iAcceptTerms:
      'J’ai lu et j’accepte les <a class="text-interaction" href="{termUrl}" target="blank">Conditions d’utilisation</a> ainsi que mes données personnelles soient traitées dans le cadre MonGuichet.mc.',
    checkYourMailbox: 'Je vérifie ma boîte mail',
    finalize:
      "Pour finaliser votre inscription, veuillez cliquer sur le <b>lien d'activation</b> qui vous a été envoyé sur votre adresse email.",
    finish: 'Terminer',
    socialReason: 'Raison sociale',
    NISNumber: 'Numéro NIS',
    CARNumber: 'Numéro CAR',
    RCINumber: 'Numéro RCI',
    socialReasonTooltip:
      "Nom de l'entreprise défini lors de sa création et qui figure au registre du commerce.",
    NISNumberTooltip:
      "Numéro d’Identification Statistique attribué par l'Institut Monégasque de la Statistique et des Études Économiques (IMSEE) lors de la création d'une activité.\n",
    NISNumberTooltip2:
      'Le NIS est composé de quatre chiffres, une lettre et cinq chiffres.',
    CARNumberTooltip:
      'Numéro employeur fourni par les Caisses Sociales de Monaco.',
    RCINumberTooltip:
      "Numéro d’immatriculation au Répertoire du Commerce et de l'Industrie.\n",
    RCINumberTooltip2:
      'Le RCI est composé du millésime sur deux chiffres, la lettre "P" ou "S", et cinq chiffres correspondant au numéro d\'inscription.',
    optionalInfos:
      'Ces informations sont facultatives, vous pourrez les compléter ultérieurement dans votre profil',
    socialReasonPlaceholder: 'Ex : MonEntreprise',
    NISNumberPlaceholder: 'Ex : 1234A56789',
    CARNumberPlaceholder: 'Ex : CAR',
    RCINumberPlaceholder: 'Ex : 09P12345',
  },
  home: {
    subtitle:
      'Retrouvez les démarches réalisables en ligne et suivez vos demandes',
    mainMessage:
      'Le portail des <span class="text-red_individual">services en ligne</span> du Gouvernement Princier et de la Mairie de Monaco',
    mainMessagePro:
      'Le portail des <span class="text-brand_blue_secondary">services en ligne</span> du Gouvernement Princier et de la Mairie de Monaco',
    followOngoingProcedures: 'Suivre mes demandes',
    followMyAppointments: 'Mes rendez-vous',
    findOnlineService: 'Trouver un service en ligne',
    fastAccesses: 'Accès rapides',
    myActivity: 'Mon activité',
    onlineServices: 'Services en ligne',
    myFavourites: 'Mes favoris',
    survey: {
      title: 'Donnez votre avis !',
      textIndividuals: `Aidez-nous à améliorer votre espace personnel MonGuichet.mc`,
      textProfessionals: `Aidez-nous à améliorer l’espace personnel MonGuichet.mc pour les professionnels.`,
      surveyButtonLabel: 'Répondre au sondage',
    },
    cards: {
      myProcedures: 'Mes démarches',
      myAppointments: 'Mes rendez-vous',
      documentsHolder: 'Mon porte-documents',
    },
    mconnectCard: {
      description:
        'Grâce à votre <span class="text-red_individual">identité numérique</span> Monégasque :',
      followAppointments: 'Suivez vos rendez-vous',
      secureConnection: 'Sécurisez votre connexion',
      simplifyMyProcedures: 'Simplifiez vos démarches en ligne',
      documentsHolder: 'Bénéficiez d’un porte-documents',
      askCivilStatusCertificates:
        'Demandez des actes d’Etat-Civil et de Nationalité',
      useMConnect: 'Utiliser',
    },
    mconnect:
      'Dorénavant, <strong class="font-semibold">vous devez utiliser MConnect</strong> pour accéder à votre compte Particuliers.',
    individuals: 'Particuliers',
    professional: 'Entreprises',
    revocation: 'Complétez vos informations d’identité numérique.',
    revocationActionLabel: 'Complétez mes informations',
  },
  actions: {
    modify: 'Modifier',
    cancel: 'Annuler',
    confirm: 'Valider',
    continue: 'Continuer',
    yes: 'Oui',
    no: 'Non',
    previous: 'Précédent',
    next: 'Suivant',
    select: 'Sélectionner',
    selectDocumentType: 'Sélectionner un type',
    search: 'Rechercher',
    addDocument: 'Ajouter un document',
    filter: 'Affiner',
    deleteFilters: 'Effacer les filtres',
    keepDocument: 'Conserver le document',
    keepDocuments: 'Conserver les documents',
    delete: 'Supprimer',
    deleteSelection: 'Supprimer | Supprimer les documents',
    mobileDeleteSelection:
      'Supprimer {count} document | Supprimer {count} documents',
    download: 'Télécharger',
    downloadSelection: 'Télécharger | Télécharger les documents',
    mobileDownloadSelection:
      'Télécharger {count} document | Télécharger {count} documents',
    selectAll: 'Tout sélectionner',
    unselectAll: 'Tout désélectionner',
    apply: 'Appliquer',
    terminate: 'Terminer',
    retry: 'Réessayer',
  },
  errors: {
    usernameContainsWhitespaces: 'L’identifiant ne doit pas contenir d’espaces',
    usernameDoesNotRespectPosixStandard:
      'Seuls les caractères alphanumériques, le point et les tirets peuvent être utilisés',
    addressMustBeValid: "L'adresse saisie est incorrecte",
    usernameFirstCharacterMustBeALetter:
      'Le premier caractère doit être une lettre',
    mandatoryField: 'Ce champ est obligatoire',
    invalidEmail:
      'Adresse e-mail invalide ou vous ne disposez d’aucun compte activé',
    incorrectEmail: "L'adresse email est incorrecte",
    emailsMustMatch:
      "L'adresse e-mail et sa confirmation doivent être identiques.",
    incorrectPassword: 'Le mot de passe est incorrect',
    passwordsMustMatch:
      'Le mot de passe et sa confirmation doivent être identiques.',
    mustNotContainANumber: 'Ce champ ne doit pas contenir de chiffre',
    minLength: 'Ce champ doit contenir au minimum {limit} caractères',
    minPasswordLength: 'Le mot de passe doit faire {limit} caractères minimum',
    passwordShouldContainLowercaseLetter:
      'Le mot de passe doit contenir au moins une minuscule.',
    passwordShouldContainUppercaseLetter:
      'Le mot de passe doit contenir au moins une majuscule.',
    passwordShouldContainANumber:
      'Le mot de passe doit contenir au moins un chiffre.',
    passwordShouldContainASpecialCharacter:
      'Le mot de passe doit contenir au moins un symbole',
    maxLength: 'Ce champ doit contenir au maximum {limit} caractères',
    password: {
      mustContain: 'Le mot de passe doit contenir : ',
      length: '9 caractères minimum',
      uppercase: 'au moins 1 majuscule',
      lowercase: 'au moins 1 minuscule',
      number: 'au moins 1 chiffre',
      special:
        "au moins 1 caractère spécial parmi () ? ! _’; : {'@'} # & * + =",
    },
    usernameAlreadyTaken:
      'Identifiant existant. Si vous avez déjà créé un compte sur le site de Service Public, identifiez-vous avec votre identifiant habituel.',
    expectedDateFormat: 'Le format attendu est JJ/MM/YYYY',
    dateMustBeBeforeToday: 'La date doit être antérieur à la date du jour',
    somethingWentWrong: 'Quelque chose ne va pas',
    emailSameAsOld: 'Cette adresse e-mail est la même que l’ancienne.',
    wrongCredential:
      'Identifiant et/ou Mot de passe incorrect. Rappel : Si vous avez déjà utilisé MConnect pour accéder à votre compte Particuliers, identifiez-vous avec MConnect uniquement.',
    numericIdIsOnlyForIndividuals:
      'L’identité numérique est disponible uniquement pour les particuliers, nous ne sommes pas en mesure de lier votre identité numérique à un compte Entreprises.',
    captchaError: "Le captcha n'a pas été verifié",
    invalidFileSize:
      'La taille du fichier sélectionné excède la limite de {limit} Mo',
    invalidFileExtension: 'L’extension du fichier sélectionné est invalide',
  },
  forgotIdentifier: {
    title: 'Identifiant oublié ?',
    hint: 'Pour retrouver votre identifiant, veuillez saisir l’adresse e-mail utilisée lors de la création de votre compte.',
    confirmationTitle: 'Vérifiez votre boîte mail',
    sent: 'Votre identifiant vous a été envoyé par e-mail.',
  },

  profile: {
    profile: 'Votre profil',
    account: 'Mon compte',
    usernames: 'Mes identifiants',
    username: 'Identifiant',
    password: 'Mot de passe',
    confirmPassword: 'Confirmation mot de passe',
    aboutMe: 'À propos de moi',
    homeAddress: 'Mon domicile',
    companyAddress: 'Adresse de mon entreprise',
    myPersonalInfo: 'Mes informations personnelles',
    myInfo: 'Mes informations',
    companyInfo: 'Informations entreprise',
    email: 'Adresse e-mail',
    modifyEmail: "Modifier l'adresse e-mail",
    modifyPassword: 'Modifier le mot de passe',
    identifier: 'Identifiant',
    technicalIdentifier: 'Compte',
    emailConfirmation: 'Confirmation adresse e-mail',
    contactEmail: 'Adresse e-mail de contact ',
    login: 'Me connecter',
    identity: 'Mon identité',
    contactInfos: 'Mes informations de contact',
    address: 'Mon adresse',
    company: 'Mon entreprise',
    personalInfos: {
      surname: "Nom d'usage",
      MconnectSurname: 'Nom de naissance',
      firstName: 'Prénom',
      secondName: 'Second prénom',
      thirdName: 'Troisième prénom',
      nationality: 'Nationalité',
      civility: {
        civility: 'Civilité',
        madam: 'Madame',
        sir: 'Monsieur',
        modify: 'Modifier ma civilité',
      },
      residencePermitHolder: 'Titulaire d’une carte de séjour',
    },
    aboutMyCompany: {
      aboutMyCompany: 'Informations de mon entreprise',
      socialReason: 'Raison sociale',
      nisNumber: 'Numéro NIS',
      carNumber: 'Numéro CAR',
      rciNumber: 'Numéro RCI',
    },
    addressInfos: {
      streetAddress: 'Adresse de résidence',
      companyStreetAddress: "Adresse de l'entreprise",
      additionalAddress: "Complément d'adresse",
      secondAdditionalAddress: "Second complément d'adresse",
      additionnalAddressPlaceholder:
        'Société, lieu-dit, bât., appart., étage, BP,..',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      additionalAddressLabel: 'Complément d’adresse',
    },
    changeEmail: {
      title: 'Modifier votre adresse e-mail',
      enterPassword: ' Veuillez d’abord saisir votre mot de passe',
      passwordLabel: ' Mot de passe',
      passwordPlaceholder: 'Mot de passe',
      enterEmail: 'Saisissez votre nouvelle adresse e-mail',
      emailLabel: 'Nouvelle adresse e-mail',
      confirmEmailLabel: 'Confirmation nouvelle adresse e-mail',
      success: 'Votre email a bien été mis à jour',
      confirmationSentence: 'Un e-mail de confirmation vous a été transmis.',
      backToProfile: 'Retourner à mon profil',
    },
    confirmEmail: {
      title: 'Vérifiez votre boîte mail',
      text: "Pour activer votre nouvelle adresse e-mail, </br> veuillez cliquer sur le lien d'activation qui vous a été envoyé sur l’adresse e-mail indiquée.",
    },
    unsubscribe: 'Me désinscrire',
    unsubscribePopup: {
      body: 'Si vous souhaitez vous désinscrire, vous pouvez adresser un message en cliquant ici : <a class="text-interaction cursor-pointer" href="https://contacts.gouv.mc/contactez-monguichet" target="blank">https://contacts.gouv.mc/contactez-monguichet</a> en indiquant votre identifiant et votre adresse email de contact.',
      title: 'Vous désinscrire?',
      footer:
        'En demandant la désinscription, vous ne pourrez plus vous connecter avec ce compte et vous serez désinscrit de tous les téléservices auxquels vous étiez inscrits.',
    },
    digitalIdentity: {
      verifiedBy: 'Verifié par',
      migrateToTitle: "Passez à l'identité numérique",
      migrateToLabel:
        'Que vous soyez monegasque ou résident, passez à l’identité numérique pour bénéficier de services inédits et réduire vos nombres d’identifiants et de mots de passe !',
      useMConnect: 'Utiliser MConnect pour me connecter à MonGuichet',
      getMConnect: 'Obtenir l’identité numérique',
      title: 'Mon Identité Numérique',
      name: 'Nom de naissance',
      nameUse: "Nom d'usage",
      firstName: 'Prénom(s)',
      nationality: 'Nationalité',
      youAre: 'Vous êtes',
      birth: 'Date de naissance',
      cityOfBirth: 'Ville de naissance',
      countryOfBirth: 'Pays de naissance',
      registrationAuthority: "Autorité d'enregistrement",
      resident: 'Résident',
      nonResident: 'Non Résident',
      monagasque: 'Monégasque',
      policeDepartment: 'Direction de la sûreté publique',
      cityHall: 'Mairie de Monaco',
      digitalServicesDepartment: 'Direction des Services Numériques',
      information:
        'Ces informations sont erronées ? Contactez le support de l’identité numérique en cliquant ici !',
    },
    digitalIdentityRegister: {
      title: 'Finaliser la création de votre compte',
      subtitle:
        'Veuillez renseigner votre adresse pour finaliser la création de votre compte.',
      addressLabel: 'Adresse',
      addressPlaceholder: 'Ex : 4, allée des roses',
      additionnalAddressPlaceholder: 'Complément d’adresse',
      zipCodeLabel: 'Code postal',
      zipCodePlaceholder: 'Ex : 98000',
      cityLabel: 'Ville',
      cityPlaceholder: 'Ex : Monaco',
      countryLabel: 'Pays',
      select: 'Sélectionner',
      button: 'Créer mon compte',
    },
    fromProcedure: {
      backToProcedure: 'Reprendre ma démarche',
      warning:
        'Vous avez entamé une démarche en ligne. Vous pouvez modifier vos informations ci-dessous, puis reprendre votre démarche.',
    },
    useMconnectBox: {
      title: 'Passez à l’identité numérique',
      content:
        '<span class="font-semibold">Monégasques ou résidents</span>, bénéficiez de services inédits et réduisez vos nombres d’identifiants et de mots de passe!',
      button: 'Utiliser MConnect',
      linkLabel: 'Obtenir l’identité numérique',
      link: 'https://mconnect.gouv.mc/decouvrir',
    },
    useMconnectPage: {
      route: '/utiliser-mconnect',
      title: 'Utiliser MConnect pour me connecter à MonGuichet',
      alertMessage:
        'Vous allez <strong>associer votre compte MonGuichet.mc</strong> à votre Identité Numérique.<br/> Une fois votre compte associé, vous devrez utiliser <strong>MConnect pour vous connecter</strong> aux services en ligne.',
      youMust: 'Pour utiliser MConnect, vous devez :',
      step1: '1. Avoir activé votre identité numérique Monégasque',
      info1: 'Comment obtenir et activer l’identité numérique',
      step2:
        '2. Avoir installé l’application MConnect Mobile sur votre smartphone',
      info2: 'Comment installer MConnect Mobile',
      connectBtn: 'Me connecter avec MConnect',
      installLink: 'https://mconnect.gouv.mc/utiliser/installer-sur-mobile',
    },
    revocation: {
      notCompleted: {
        title: 'Informations à compléter',
        announcement:
          'Il manque des informations permettant de renforcer la sécurité de votre compte',
        subtitle:
          '<span class="font-semibold">Complétez vos informations d’identité numérique</span> pour :',
        explanation1:
          'Être notifié du renouvellement de l’usage de votre identité numérique',
        explanation2:
          'En cas de perte ou de vol de votre carte, bloquer votre identité numérique',
        actionLabel: 'Compléter mes informations',
        knowMore: 'En savoir plus sur mconnect.gouv.mc',
      },
      completed: {
        title: 'Informations complétées',
        announcement: 'Vos informations de compte sont à jour',
        subtitle: 'Dès maintenant, vous pouvez :',
        explanation1:
          'Mettre à jour vos données de contact en cas de changement',
        explanation2:
          'En cas de perte ou de vol de votre carte, bloquer votre identité numérique',
        actionLabel: 'Accéder au service',
        knowMore: 'En savoir plus sur mconnect.gouv.mc',
      },
    },
  },
  services: {
    title: 'Mes démarches',
    followMyServicesTitle: 'Voir toutes mes démarches',
    subtitle: 'Retrouvez ici vos démarches réalisées',
    updated: 'Mise à jour',
    accessTo: 'Accéder',
    update: 'Compléter',
    categories: 'Filtres | Filtres :',
    request: 'demande | demandes',
    beingProcessed: 'En cours',
    beingProcessedFullLabel: 'En cours de traitement',
    waitingForUserRequest: 'En attente',
    waitingForUserRequestFullLabel: 'En attente d’action',
    treated: 'Traitées',
    refused: 'Refusé',
    completedRequest: 'Terminé',
    delete: 'Effacer',
    showResults: 'Afficher les résultats',
    informations: {
      title: 'Vous n’avez pas trouvé le suivi de votre demande en ligne ?',
      paperApplication: 'Vous avez fait une demande papier :',
      paperApplicationExplanation:
        'Nous ne proposons pas encore le suivi des démarches qui n’ont pas été réalisées en ligne.',
      onlineApplication: 'Vous avez fait une demande en ligne :',
      onlineApplicationExplanation:
        'Nous n’avons pas encore relié toutes les démarches à MonGuichet.mc.',
      onlineApplicationTracking:
        'Retrouvez le suivi directement dans le Téléservice concerné.',
      missingOnlineApplication: 'Le suivi de votre demande a disparu ?',
      missingOnlineApplicationExplanation:
        'Votre demande pourrait avoir été supprimée si elle avait atteint sa durée de conservation maximale.',
    },
    announcementLabel:
      'Vous avez une demande en attente d’action de votre part. Veuillez la compléter afin que l’Administration puisse la traiter. | Vous avez des demandes en attente d’action de votre part. Veuillez les compléter afin que l’Administration puisse les traiter.',
    noServices: {
      title: 'Aucune démarche en cours',
      description:
        'Seules les démarches que vous avez effectuées <span class="font-semibold">en ligne</span> peuvent apparaitre <span class="font-semibold">ici</span>.',
      action: 'Trouver un service en ligne',
    },
  },
  error: {
    page404: {
      title: 'La page demandée n’existe pas',
      subtitle: "La page n'existe pas ou n'est plus disponible (erreur 404)",
      back: 'Retourner à la page d’accueil',
      contact: 'Contacter le responsable du site',
    },
    page500: {
      title: 'Une erreur est survenue',
      subtitle: 'Une erreur interne est survenue (erreur 500)',
    },
  },
  onlineProcedures: {
    title: 'Services en ligne',
    subtitle: 'Retrouvez ici les démarches que vous pouvez réaliser en ligne.',
    searchText: 'Rechercher une démarche ou un service en ligne ',
    categories: 'Filtres :',
    results: 'résultat  | résultats ',
    tabs: {
      explore: 'Explorer',
      myFavourites: 'Mes favoris',
    },
    noFavourites: {
      title: 'Aucun favori',
      add: 'Pour ajouter un service en favori :',
      item1: "Allez dans l'onglet Explorer",
      item2: 'Trouvez le service qui vous intéresse',
      item3: 'Cliquez sur les trois petits points',
      item4: 'Cliquez sur "Ajouter aux favoris"',
    },
    card: {
      consult: 'Comprendre la démarche',
      access: 'Accéder au service',
      frenchAvailability: '',
      englandCode: 'EN',
      franceCode: 'FR',
      mconnectOnly: 'Service accessible uniquement via ',
      mconnectOnlyFull: 'Service accessible uniquement via MConnect',
      cityHallService: 'Démarche de la Mairie de Monaco',
      addToFavourites: 'Ajouter aux favoris',
      removeFromFavourites: 'Supprimer des favoris',
    },
    navigation: {
      title: 'Vous n’avez pas trouvé ce que vous cherchiez ?',
      servicePublicIndividuals: 'MonServicePublic',
      servicePublicCompany: 'MonEntreprise',
      notOnlineYet:
        'Votre démarche n’est peut-être pas encore réalisable en ligne. <br/>Trouvez la procédure à suivre sur le site de {siteName}.gouv.mc.',
      publicServiceButtonLabel: 'Accéder à {siteName}',
      scrollTop: 'Retourner en haut de page',
    },
    noResults: {
      checkSpelling:
        "Vérifiez l'orthographe des mots-clés et essayez de changer certains mots-clés (par ex. 'voiture' au lieu de 'voitures'). Vous pouvez également explorer une thématique (ex. emploi, etc...) dans notre catalogue des démarches.",
      notAvailable:
        'Votre démarche n’est peut-être pas encore réalisable en ligne. Trouvez la procédure à suivre sur le site de {siteName}.gouv.mc.',
    },
    availableServicesButtonLabel: 'Accéder au catalogue des démarches',
    availableLanguages: '',
    deleteFavourite: {
      title: 'Supprimer un favori',
      warning: 'Êtes-vous sûr de vouloir supprimer ce favori ?',
      keep: 'Annuler',
      delete: 'Supprimer',
    },
    mconnectOnly: {
      title: 'Service accessible via MConnect',
      warning:
        'Pour accéder à ce service, vous devez utiliser la connexion via MConnect.',
      description1:
        'Actuellement, vous êtes connecté à MonGuichet.mc avec votre identifiant et votre mot de passe.',
      description2:
        'Si vous avez déjà une identité numérique Monégasque, vous pouvez dès maintenant utiliser MConnect pour vous connecter à MonGuichet et accéder à ce service.',
      description3:
        'Si vous n’avez pas d’identité numérique Monégasque, cliquez sur "obtenir une identité numérique".',
      useMyNumericIdentity: 'Utiliser mon identité numérique',
      getANumericIdentity: 'Obtenir une identité numérique',
      cancel: 'Annuler',
    },
  },

  appointments: {
    title: 'Mes rendez-vous',
    subtitle: 'Retrouvez ici vos rendez-vous administratifs pris en ligne',
    next: 'Prochain RDV',
    bookAnAppointment: 'Prendre rendez-vous',
    subheader: {
      incoming: 'À venir',
      passed: 'Passés',
    },
    followingCard: {
      incoming: 'Rendez-vous à venir',
      passed: 'Rendez-vous passés',
      seeAll: 'Voir tous mes rendez-vous',
    },
    description: {
      incoming: 'rendez-vous à venir :',
      noneIncoming: 'Aucun rendez-vous à venir',
      passed: 'rendez-vous passé : | rendez-vous passés :',
      nonePassed: 'Aucun rendez-vous passé',
    },
    noAppointments: {
      incomingTitle: 'Aucun rendez-vous à venir',
      passedTitle: 'Aucun rendez-vous passé',
      description:
        'Seuls les rendez-vous que vous avez pris <span class="font-semibold">en ligne</span> peuvent apparaître <span class="font-semibold">ici</span>',
    },
    notFound: {
      title: 'Vous n’avez pas trouvé le suivi de votre rendez-vous ?',
      explanation1:
        "Actuellement, vos rendez-vous pris en ligne avec la Section des Résidents de la DSP et le Service des Titres de Circulation (STC) apparaissent ici. Nous n'avons pas encore relié tous les services de rendez-vous à MonGuichet.mc.",
      explanation2:
        'Retrouvez le suivi directement dans le courriel ou SMS de confirmation de votre rendez-vous.',
      explanation3:
        'Les rendez-vous sont affichés dans votre espace <span class="font-semibold">pendant 1 mois</span> après la date du rendez-vous. Passé ce délai, ils sont automatiquement supprimés.',
    },
    dropdown: {
      headerLabel: 'Prendre rendez-vous',
      bodyContent:
        'Vous pouvez prendre directement rendez-vous en ligne avec certains services de l’Administration. Dans un premier temps, seuls certains sites permettent de retrouver le suivi du rendez-vous dans votre espace MonGuichet.mc.',
    },
    modifyAppointment: 'Modifier',
    vehiclePlateNumber: 'Immatriculation :',
    addAppointment: 'Ajouter à mon agenda',
    reason: 'Motif :',
    with: 'Avec :',
    place: 'Lieu :',
    statuses: {
      pending: 'En attente d’action',
      scheduled: 'Planifié',
      cancelled: 'Annulé',
      declined: 'Refusé',
      noShow: 'Absent',
      completed: 'Réalisé',
      inProgress: 'En cours de traitement',
    },
  },

  docHolder: {
    noDocument: {
      title: 'Aucun document',
      description:
        'Vous pouvez ajouter des documents à partir de votre ordinateur ou votre téléphone mobile.',
      explanationTitle: 'Le porte-documents vous permet de :',
      explanationFirstStep:
        '<b>Ajouter</b> vos documents souvent utilisés dans les démarches (<b>justificatif de domicile, justificatif d’identité...</b>) depuis MonGuichet ou un téléservice',
      explanationSecondStep:
        '<b>Centraliser</b> vos documents dans votre espace personnel MonGuichet',
      explanationThirdStep:
        '<b>Réutiliser</b> vos documents d’une démarche à l’autre, <b>depuis n’importe quel appareil</b>',
    },
    documentsDisplay: {
      count: 'document sélectionné | documents sélectionnés',
      description:
        'Vous êtes responsable de la durée de conservation de vos documents. Ces derniers seront conservés tant que votre compte MonGuichet est actif et que votre consentement lié à la fonctionnalité porte-documents est valide. Vous pouvez utiliser les documents déposés ici dans vos démarches en ligne.',
      add: 'Ajouté le :',
      sizeLabel: 'Taille :',
      sizeUnit: 'Mo',
      format: 'Format :',
      type: 'Type de justificatif :',
      endOfValidity: 'Fin de validité :',
      file: "Fichier d'origine :",
      docInfos: 'Informations du document',
      select: 'Sélectionnez un document pour en savoir plus',
      filters: {
        filterBy: 'Filtrer par',
        byJustificativeTypeLabel: 'Filtrer par type de justificatif',
        allType: 'Tous les types de justificatif',
        byAdditionDate: "Trier par date d'ajout",
        antechronologicalOrder: 'Du plus récent au plus ancien',
        chronologicalOrder: 'Du plus ancien au plus récent',
      },
      shouldDisplayMultipleSelection:
        'Activer la sélection multiple de documents',
      activated: 'Activée',
      deactivated: 'Désactivée',
      noResults: 'Aucun résultat',
    },
    addDocument: {
      title: 'Ajouter un document',
      fileLabel: 'Fichier',
      firstCondition: 'Le fichier ne doit pas excéder {size} Mo.',
      secondCondition: 'Formats acceptés : {types}',
      documentName: 'Nom du document',
      documentNamePlaceholder: 'ex : mon_fichier_2023',
      documentType: 'Type de justificatif',
      endOfValidity: 'Fin de validité',
      endOfValidityDateFormat: 'JJ/MM/AAAA',
      loadingLabel: 'Chargement de votre document ...',
      successLabel: 'Le chargement a abouti avec succés !',
      failureLabel: 'Le chargement a échoué !',
    },
    termsOfUse: {
      title: 'Politique de protection des données',
      subtitle: 'Utilisation de votre porte-documents MonGuichet.mc :',
      content1:
        'Sous réserve de votre consentement (case à cocher ci-dessous), vous pouvez déposer et stocker des documents ainsi qu’autoriser leur récupération par un téléservice pour vos démarches.',
      content2:
        'Ces documents peuvent contenir des données personnelles. Ils sont enregistrés et conservés dans votre Porte-documents sur votre compte MonGuichet.mc par la Direction des Services Numériques de l’Etat de Monaco, en tant que responsable du traitement, afin de faciliter votre utilisation des téléservices.',
      content3:
        'Vous disposez des droits suivants : droit d’accès aux données, droit de retirer votre consentement à tout moment, droit de demander que soient rectifiées ou supprimées les données inexactes, incomplètes ou périmées.',
      content4:
        'Toutes les informations relatives aux traitements de vos données personnelles sont disponibles dans les <a href="{termsOfUseLink}" target="_blank" class="text-interaction underline">CGU</a>.',
      legal:
        'J’accepte que mes documents soient traités par le service Porte-documents<span class="text-red">*</span>',
      actionConsent: "J'accepte",
      actionDeny: 'Je refuse',
    },
    modifyDocument: {
      title: 'Modifier un document',
    },
    deleteDocument: {
      title: 'Supprimer un document',
      warning: 'Êtes-vous sûr de vouloir supprimer ce document ?',
      description: 'Il n’apparaitra plus dans votre porte-documents.',
      loadingLabel: 'Suppression de votre document ...',
      successLabel: 'La suppression a abouti avec succés !',
      failureLabel: 'La suppression a échoué !',
    },
    massDeleteDocument: {
      title: 'Supprimer les documents sélectionnés',
      warning: 'Êtes-vous sûr de vouloir supprimer ces documents ?',
      description: 'Ils n’apparaitront plus dans votre porte-documents.',
      loadingLabel: 'Suppression de vos documents ...',
    },
    documentDetails: {
      title: 'Détails du document',
    },
    deleteDocHolder: {
      action: 'Supprimer mon porte-documents',
      confirmation:
        'Êtes-vous sûr de vouloir supprimer votre espace porte-documents ?',
      warning:
        'Votre consentement au porte-documents ainsi que vos documents déposés seront automatiquement supprimés',
      keep: 'Conserver mon porte-documents',
      delete: 'Supprimer',
      loadingLabel: 'Suppression de votre porte-documents ...',
      successLabel:
        'La suppression de votre porte-documents a abouti avec succés !',
      failureLabel: 'La suppression de votre porte-documents a échoué !',
    },
    announcement: {
      warningDeactivation:
        'Votre consentement à l’usage du porte-documents arrivera à expiration le : {date}.<br/>Si vous souhaitez conserver l’usage de votre porte-documents, merci de renouveler votre consentement.',
      warningDeletion:
        'Sans votre consentement, vos documents seront automatiquement supprimés le {date}.<br/>Si vous souhaitez conserver l’usage de votre porte-documents, merci de renouveler votre consentement.',
      action: 'Renouveller mon consentement',
    },
  },
  head: {
    businesses: 'Entreprises',
    individuals: 'Particuliers',
    connecting: 'Connexion',
    register: 'Création de compte',
    forgotIdentifier: "Oubli d'identifiant",
    error: 'Erreur',
  },
  apiGichuni: {
    title: 'Specification API',
  },
  porteDoc: {
    title: 'Porte doc test',
  },
  termsOfUse: {
    title: 'Conditions Générales d’Utilisation',
    headline: `Les présentes Conditions générales d’utilisation ont pour objet de préciser les modalités d’utilisation et de navigation au sein du présent Portail appelé « MonGuichet.mc ».<br/><br/>
    L’Administration se réserve le droit, à tout moment, de modifier et/ou d’interrompre temporairement ou définitivement tout ou partie de ce Portail.<br/><br/>
    Les présentes Conditions générales d’utilisation sont opposables pendant toute la durée d’utilisation du Portail, jusqu’à ce que de nouvelles Conditions générales d’utilisation remplacent les précédentes.<br/><br/>
    Tout usage du Portail après modification des Conditions générales d'utilisation vaut acceptation de ces dernières.<br/><br/>
    Les Conditions générales d’utilisation qui figurent en ligne prévalent sur toute autre version.<br/><br/>
    L'Usager peut à tout moment renoncer à utiliser le Portail. Il reste cependant responsable de son utilisation antérieure.<br/><br/>
    L’Usager reconnaît que l'accès au Portail nécessite le respect de l'ensemble des prescriptions définies au sein des présentes.<br/><br/>
    Les présentes Conditions s'appliquent à tout Usager accédant à MonGuichet.mc.<br/><br/>
    Chaque Usager doit accepter, sans réserve, les présentes Conditions lorsqu'il accède pour la première fois à MonGuichet.mc.<br/><br/>
    L'Usager confirme avoir lu et comprend l'intégralité des présentes Conditions avant toute utilisation des Services proposés par MonGuichet.mc et s'engage à les respecter.`,
    definition: {
      title: '1. Définitions',
      description:
        'Les mots et expressions ci-après commençant par une lettre majuscule, au singulier ou au pluriel, sont employés dans les présentes avec la signification suivante :',
      administration: `<strong class='italic'>« Administration »</strong> : désigne l’Etat de Monaco / le Gouvernement Princier de Monaco / Direction des Services Numériques ;`,
      personalAccount: `<strong class='italic'>« Compte personnel »</strong> : désigne le compte créé par l'Usager pour accéder à MonGuichet.mc ;`,
      termsOfUse: `<strong class='italic'>« Conditions générales d’utilisations » ou « CGU »</strong> : désigne les présentes Conditions Générales d’Utilisation ;`,
      onlineServiceAccount: `<strong class='italic'>« Compte téléservice »</strong> : désigne le compte utilisé par l’Usager, avant la mise en place de MonGuichet.mc, pour accéder aux Téléservices de l'Administration ;`,
      procedure: `<strong class='italic'>« Démarche »</strong> : désigne l'ensemble des actions et interactions que peut réaliser un particulier ou un professionnel avec des services administratifs monégasques. Les démarches du Service Public sont accessibles depuis les sites du Service Public <a class="text-interaction underline" href="https://monservicepublic.gouv.mc" target="_blank">monservicepublic.gouv.mc</a> et <a class="text-interaction underline" href="https://monentreprise.gouv.mc" target="_blank">monentreprise.gouv.mc</a>. Les Démarches réalisables en ligne désignent les démarches qui peuvent être réalisées par voie électronique via un Téléservice ;`,
      personalData: `<strong class='italic'>« Donnée(s) à caractère personnel » / « Donnée(s) personnelle(s) » / « Information(s) nominative(s) »</strong> : désigne toute information se rapportant à une personne physique identifiée ou identifiable (« personne concernée »). Est réputée être une « personne physique identifiable » toute personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale ;`,
      businesses: `<strong class='italic'>« Entreprises »</strong> : désigne une personne morale. Les Démarches Entreprises désignent les Démarches qu'effectuent une personne physique pour le compte d'une personne morale dans un cadre professionnel ;`,
      userName: `<strong class='italic'>« Identifiant »</strong> : désigne un identifiant unique défini par l'Usager lors de la création de son Compte (hors service MConnect). Il permet à un Usager de s'identifier et d'accéder à MonGuichet.mc ;`,
      mconnect: `<strong class='italic'>« MConnect »</strong> : désigne la solution technique d'authentification proposée par la Direction des Services Numériques (DSN) permettant aux Usagers d'attester de leur identité dans le cadre de services proposés par les Fournisseurs de service et d'y accéder de manière sécurisée. MConnect est présenté sur le site <a class="text-interaction underline" href="https://mconnect.gouv.mc" target="_blank">mconnect.gouv.mc</a>. Pour les Conditions générales d'utilisation, se référer à chaque téléservice qui utilise MConnect ;`,
      individual: `<strong class='italic'>« Particuliers »</strong> : désigne une personne physique. Les Démarches Particuliers désignent les Démarches de la vie courante qu'effectuent une personne physique dans un cadre personnel ;`,
      website: `<strong class='italic'>« Portail »</strong> : désigne le présent site « MonGuichet.mc », <a class="text-interaction underline" href="https://monguichet.mc" target="_blank">www.monguichet.mc</a>`,
      docHolder: `<strong class='italic'>« Porte-documents »</strong> : désigne l’espace de stockage à la main de l’usagers disponible sur le Portail « MonGuichet.mc ».
        <br/>L’Usager peut, s’il a consenti à son usage :
                  <ul class="list-inside">
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Charger/stocker un document dans son porte-documents depuis le Portail ;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Utiliser un document de son porte-documents MonGuichet.mc dans une démarche en ligne ;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Enregistrer un document depuis une démarche en ligne vers le porte-documents MonGuichet.mc.</li>
                  </ul>`,
      services: `<strong class='italic'>« Services »</strong> : désigne les fonctionnalités offertes par le Portail, notamment :
                  <ul class="list-inside">
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Un catalogue des Démarches réalisables en ligne du Service Public via ses Téléservices ;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>La possibilité d’utiliser le Service MConnect pour s’identifier ;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>La possibilité de gérer son profil et mettre à jour ses données ;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Une vue consolidée des Démarches, réalisées en ligne via un Téléservice, qui permet à l’Usager de suivre l’avancement de ses demandes ;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Une vue consolidée des rendez-vous, pris en ligne via un Téléservice, qui permet à l’Usager MConnect ou Professionnel de suivre le statut de ses rendez-vous ;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Le Porte-documents qui permet à l’Usager MConnect identifié de gérer le stockage et la récupération de documents nécessaires à ses démarches par les téléservices.</li>
                  </ul>`,
      onlineServices: `<strong class='italic'>« Téléservice »</strong> : désigne, au sens de l’Ordonnance n. 3.413 du 29/08/2011 portant diverses mesures relatives à la relation entre l'Administration et l'administré, modifiée « […] <span class="italic">tout système d’information permettant aux Usagers de procéder par voie électronique à des Démarches ou formalités administratives ainsi qu’à des paiements</span> ».`,
      user: `<strong class='italic'>« Usager »</strong> : désigne toute personne physique, accédant au Portail en vue de réaliser des Démarche en ligne.`,
    },
    purpose: {
      title: '2. OBJET',
      description: `Les présentes Conditions générales d’utilisation ont pour objet de préciser les conditions d’accès et d’utilisation du Portail MonGuichet.mc qui a pour objet d'améliorer l'accessibilité des Démarches en ligne de l'Administration.<br/><br/>
          Depuis ce Portail, l’Usager peut notamment :
          <ul class="list-disc list-inside">
              <li>Se connecter en utilisant son Compte personnel ou via le service MConnect ;</li>
              <li>Accéder au catalogue des Démarches du Service Public réalisables en ligne grâce aux Téléservices ;</li>
              <li>Retrouver l'historique de ses Démarches qu’il a effectuées en ligne ;</li>
              <li>Retrouver l’historique de ses Rendez-vous pris en ligne ;</li>
              <li>Différencier ses Démarches Particuliers et Entreprises ;</li>
              <li>Différencier ses Rendez-vous Particuliers et Entreprises ;</li>
              <li>Stocker des documents qu’il estime nécessaires à l’accomplissement de ses Démarches ;</li>
              <li>Permettre aux Téléservices de récupérer des documents de son Porte-documents ;</li>
              <li>Accéder aux Services depuis tous soutient le numérique.</li>
          </ul><br/>
          La navigation entre les différents Téléservices est fluidifiée grâce à une authentification unique.`,
    },
    access: {
      title: '3. ACCES AU PORTAIL',
      description: `Les moyens d’accès au Portail sont strictement personnels et confidentiels.<br/><br/>
      A l'exception du coût éventuel de connexion, l'accès au Portail est gratuit. Les éventuels frais de connexion à Internet sont à la charge de l’Usager et ne pourront donner lieu à aucun remboursement de la part de l’Administration.<br/><br/>
      Lors de son utilisation, l’Usager est invité à renseigner un formulaire de saisie en ligne avec toutes les précautions et mesures de sécurité adéquates.<br/><br/>
      L’Usager s’engage à saisir ses données sous son entière responsabilité, contrôle et direction et s’engage à communiquer des informations complètes, exactes et actualisées, et à ne pas usurper l’identité d’un tiers.<br/><br/>
      Dans le cas où un Usager souhaiterait faire part à l’Administration d’une erreur ou d’une correction, il est prié de le faire à l’adresse e-mail suivante : <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a><br/><br/>
      L’Administration se réserve le droit de ne pas faire droit sans préavis ni indemnité d’aucune sorte aux prises de rendez-vous, illicites, abusives, ou répétitives.`,
      userName: {
        title: '3.1 Accès avec Identifiant',
        description: `L'utilisation de MonGuichet.mc se fait suite à l'authentification de l’Usager via un Compte personnel, qu’il doit créer s'il n'en dispose pas ou s'il ne souhaite pas utiliser un Compte existant.<br/><br/>
        Lors de sa première utilisation, l’Usager est invité à accéder à son Compte téléservice existant ou à le créer en complétant un formulaire de saisie en ligne.<br/>
        Pour créer un Compte personnel il est nécessaire de renseigner les éléments suivants : adresse email, titre, nom(s), prénom(s) et adresse postale. L’Usager doit appliquer à ces informations toutes les précautions et mesures de sécurité adéquates.<br/>
        L'Usager doit conserver son Identifiant et son mot de passe qui lui seront utiles pour tout accès à son Compte personnel ou aux Téléservices qui l'utilisent.<br/><br/>
        La connexion au Compte personnel s'ouvre en remplissant l'Identifiant et le mot de passe personnel de l’Usager. En cas de succès, l’Usager accède à son Compte personnel et peut bénéficier de l'ensemble des Services.<br/><br/>
        Si l’Usager oublie son Identifiant ou son mot de passe personnel, ce dernier doit cliquer sur le lien « Mot de passe oublié ? » dans l'interface de connexion. La procédure de récupération lui est alors décrite.<br/><br/>
        Il est rappelé qu'un mot de passe est personnel et confidentiel et ne doit jamais être communiqué. Le mot de passe personnel ne doit pas utiliser tout ou partie de l'Identifiant, du prénom ou du nom de famille de l’Usager. L'Usager s'engage à modifier son mot de passe dès qu'il suspecte une utilisation frauduleuse de son Compte personnel et à ne jamais stocker en clair son mot de passe.<br/><br/>
        L’Usager devra fournir une adresse e-mail valide lors de la création de son Compte personnel. Cette adresse est nécessaire et utilisée pour la confirmation des opérations réalisées par l’Usager.<br/><br/>
        L'Usager s'engage à avertir immédiatement l'Administration de toute utilisation non autorisée de ces informations. L'Administration ne saurait être tenue pour responsable des dommages éventuellement subis par l'utilisation de l'Identifiant et du mot de passe par un tiers non autorisé.<br/><br/>
        Dans le cas où un Usager souhaiterait faire part à l'Administration d'une erreur ou d'une correction, il est prié de le faire à l'adresse mail suivante : <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.`,
      },
      mconnect: {
        title: '3.2 Accès avec MConnect',
        description: `MonGuichet.mc est accessible via le service MConnect. Dans ce cas, il n'y a pas d'Identifiant ni de mot de passe spécifique au Portail.<br/><br/>
          Lors de la première utilisation du service MConnect sur MonGuichet.mc, l’Usager est invité, s'il possède déjà un Compte téléservice, à rattacher ce dernier à son authentification MConnect. Ainsi l'Usager retrouvera tout l'historique des Démarches réalisées et des Rendez-vous pris en ligne via des Téléservices avec son Compte personnel.<br/><br/>
          Pour créer un Compte personnel il est nécessaire de renseigner : son adresse email et son adresse postale.<br/><br/>
          L'Usager devra fournir une adresse e-mail valide lors de la création de son Compte personnel. Cette adresse est nécessaire et utilisée pour la confirmation des opérations réalisées par l'Usager.
          L'Usager s'engage à avertir immédiatement l'Administration de toute utilisation non autorisée de ses informations. L'Administration ne saurait être tenue pour responsable des dommages éventuellement subis par l'utilisation du service MConnect par un tiers non autorisé.<br/><br/>
          Dans le cas où un Usager souhaiterait faire part à l'Administration d'une erreur ou d'une correction, il est prié de le faire à l'adresse mail suivante : <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.`,
      },
    },
    dataRetention: {
      title: '4. CONSERVATION DES DONNEES',
      personalAccount: {
        title: '4.1 Compte personnel',
        description: `Un compte personnel qui n'est pas utilisé pendant plus de trois (3) ans entre dans un processus de désinscription automatique. Un courrier électronique prévient l'Usager de sa désinscription à venir et de la possibilité de se reconnecter dans un délai d’un mois pour éviter, s’il le souhaite, cette suppression. L'Usager peut également se désinscrire à tout moment en envoyant un email à <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.<br/><br/>
          La désinscription entraîne l'effacement définitif de toutes les données personnelles et de tous les documents stockés dans le Porte-documents de l'Usager dans un délai de dix (10) jours.<br/><br/>
          Le non-renouvellement du consentement de l’Usager pour l’utilisation de la fonctionnalité Porte-documents entraîne la suppression définitive des documents stockés sur ce dernier dans un délai d’un (1) mois.`,
      },
      loginData: {
        title: '4.2 Données de connexion',
        description:
          'Certaines données de connexion sont conservées aux fins de protection contre les intrusions et de poursuites judiciaires le cas échéant. Ces données sont conservées pendant douze (12) mois.',
      },
    },
    undertaking: {
      title:
        '5. ENGAGEMENT CONCERNANT L’UTILISATION DU PORTAIL ET DE SON CONTENU',
      description: `L'Usager s'engage à ne pas commettre sur le Portail d’acte illicite ou frauduleux.<br/><br/>
        Est prohibé par les présentes Conditions générales d’utilisation, tout acte pouvant contribuer à ternir le nom et/ou l’image de l’État de Monaco, ou pouvant endommager, surcharger, mettre hors d'état, discréditer ou mettre en échec le Portail MonGuichet.mc.<br/><br/>
        Est notamment prohibé : la transmission, par courrier électronique ou par tout autre moyen, des contenus servant à des fins de persécution, harcèlement, diffamation, discrimination, insultes, ou toute autre diffusion d'information ou d’opinion à caractère personnel sur un ou plusieurs individus ou portant atteinte aux droits de la personne ou aux usagers mineurs.<br/><br/>
        Les correspondances doivent avoir en toute circonstance un caractère courtois et doivent obéir aux règles élémentaires de la politesse.`,
    },
    security: {
      title: '6. SECURITE DU PORTAIL',
      description: `Le Portail requiert une configuration Internet suffisamment rapide ainsi que des équipements et des moyens matériels permettant d’accéder et de naviguer sur le Portail. Le navigateur doit être configuré pour autoriser les « cookies » de session.<br/><br/>
        L’Usager a conscience des risques inhérents à l’utilisation du réseau internet et en accepte les limites et les risques.<br/><br/>
        L’Administration fait ses meilleurs efforts pour sécuriser le Portail MonGuichet.mc au regard des risques encourus et de la nature des données traitées.<br/><br/>
        Il est interdit à l’Usager d’accéder ou de se maintenir, frauduleusement, dans tout ou partie du Portail, de supprimer ou modifier les données contenues sur le Portail, d’y introduire frauduleusement des données, d’altérer le Portail ou de perturber son bon fonctionnement.<br/><br/>
        L’Usager s’engage à ne pas tenter de modifier ou manipuler les pages du Portail de manière à dissimuler, détourner ou modifier ces dernières.<br/><br/>
        Il est strictement interdit : (i) de reproduire, modifier, adapter, altérer le Portail  et/ou son contenu; (ii) d’accéder ou rechercher à accéder au code source du Portail , en ayant recours à des techniques de décompilation, d’ingénierie inverse ou quelque autre moyen que ce soit; (iii) de rendre accessible ou de transmettre des parties du Portail ou du contenu dont l’accès est restreint à disposition de tiers; (iv) d’utiliser le Portail dans un but illicite, à ce titre, l’Usager s’engage à n’inclure aucune information fausse, incomplète ou inexacte, à n’inclure aucun virus, cheval de Troie, ver, bombe à retardement ou tout autre programme conçu pour endommager, produire un effet néfaste, intercepter ou contrer tout système, donnée ou information personnelle.<br/><br/>
        Il doit le cas échéant disposer d’une connexion ou d’un réseau sécurisé, d’un antivirus, d’un système d’exploitation à jour pour prévenir tant que faire se peut l’Administration de tous dommages relatifs à une utilisation du Portail non sécurisée.<br/><br/>
        L’Usager s’engage à prendre toutes les mesures appropriées de façon à protéger ses données et matériels, il est responsable de la sécurité de ses données et de son réseau qu’il utilise à ses propres risques.<br/><br/>
        Dans le cas où l’Usager aurait connaissance d’un évènement pouvant porter atteinte à la sécurité du Portail, tel qu’une erreur, faute, dysfonctionnement, intrusion, perte de données, vulnérabilité ou irrégularités, il est invité à contacter immédiatement l’Administration par email à l’adresse suivante : <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.`,
    },
    intellectualProperty: {
      title: '7. PROPRIETE INTELLECTUELLE',
      description:
        'Les présentes Conditions générales d’utilisation n’emportent aucune cession d’aucune sorte de droits de propriété intellectuelle au bénéfice de l’Usager.',
      copyright: {
        title: '7.1 Droit d’auteur',
        description: `L'intégralité des droits attachés à tout contenu publié sur MonGuichet.mc est la propriété exclusive de l'Administration, sauf mention contraire affectant la propriété à un tiers. De manière non exhaustive, sont ainsi inclus, tous les textes, commentaires, titres, dénominations, photographies, sons, images, données, dessins, séquences sonores aimées, vidéos, services et chartes graphiques et sont protégés par les dispositions légales en vigueur, relatives à la protection du droit d'auteur en Principauté de Monaco.<br/><br/>
        Toute forme d'utilisation, de représentation, de transformation, de reproduction, d'adaptation ou de diffusion, intégrale ou partielle, de tout élément ou du contenu de MonGuichet.mc sur quelque support et par quelque procédé que soit est interdit, à défaut d'autorisation expresse de l'Administration et constitue une infraction pénalement sanctionnée par les Cours et Tribunaux monégasques.`,
      },
      trademarks: {
        title: '7.2 Marques',
        description: `Les marques et/ou logos dont sont titulaires l'Administration, apparaissant sur ce Portail, sont des marques protégées par les dispositions légales applicables à Monaco. Toute reproduction, en tout ou partie, sans l'autorisation du titulaire constitue une infraction pénalement sanctionnée par les Cours et Tribunaux monégasques.`,
      },
    },
    liability: {
      title: '8. RESPONSABILITE',
      description: `Le Portail ne doit pas être utilisé de façon abusive ou malveillante. L’Administration ne saurait être tenue pour responsable vis-à-vis de l’Usager des dommages de toute nature, directs ou indirects, résultant de l’utilisation du Portail, ni de son indisponibilité.<br/><br/>
        De manière générale, l’Usager s’engage à utiliser les Services :
            <ul class="list-disc list-inside">
              <li>Dans le respect des lois, de la règlementation monégasque, des droits des tiers ainsi que dans le respect des droits de propriété intellectuelle ;</li>
              <li>De manière loyale et conformément à sa destination ;</li>
              <li>Sous sa responsabilité exclusive.</li>
            </ul>
        <br/>
        L’Usager reconnaît et accepte que la responsabilité de l’Administration ne peut être engagée au titre du Portail, notamment en cas d’intrusion, d’altération ou de détournement des données ou du réseau et, de toute utilisation illicite ou préjudiciable à l’Usager ou à un tiers.<br/><br/>
        L’Administration s’efforce de tenir à jour et exactes les informations publiées sur le présent Portail mais décline toute responsabilité concernant l’utilisation d’une information erronée ou obsolète.<br/><br/>
        L’Administration ne garantit pas la continuité, l'accès et la disponibilité du fonctionnement du Portail et des services y afférents.<br/><br/>
        L’Usager du Portail est tenu de respecter les présentes Conditions générales d’utilisation et s’interdit dans ce cadre, toute utilisation ou tentative d’utilisation du Portail et de ses fonctionnalités à des fins autres que celles prévues par les présentes.`,
    },
    protectionPersonalData: {
      title: '9. PROTECTION DES DONNEES A CARACTERE PERSONNEL',
      description: `Conformément aux dispositions applicables en matière de protection des Données à caractère personnel en Principauté de Monaco, les informations recueillies par le biais de MonGuichet.mc sont imposées par la Direction des Services Numériques de l'État de Monaco qui agit en qualité de responsable du traitement et exploite un traitement ayant pour finalité « Gestion du Compte permettant aux Usagers d'entreprendre et de suivre des Démarches et Rendez-vous par Téléservices ».<br/><br/>
        Le traitement est justifié par :
            <ul class="list-disc list-inside">
              <li>Le consentement de la personne concernée ;</li>
              <li>La réalisation d'un intérêt légitime poursuivi par l'Administration, en application de l'Ordonnance 3.413 du 29/08/2011 portant diverses mesures relatives à la relation entre l'Administration et l'administré (améliorer l'accès, l'usage et le suivi des Démarches par Téléservice pour l’Usager, le suivi des Rendez-vous pris en ligne par l’Usager).</li>
            </ul><br/>
        Les informations traitées dans le cadre des Services de MonGuichet.mc sont destinées à l'Administration et ne font l'objet d'aucune communication à des fins commerciales ou publicitaires.<br/><br/>
        Les informations conservées sont conservées pendant les durées suivantes :
            <ul class="list-disc list-inside">
              <li>Les informations relatives à l'identité, à l'adresse, à l'Identifiant, au mot de passe et à la qualification du Compte sont conservées trois (3) ans, à compter de la dernière connexion de l’Usager ;</li>
              <li>Les documents qu’il a décidé de placer dans son porte-documents, ces mêmes documents sont conservés tant que le compte est actif ;</li>
              <li>Les informations relatives à l'historique de navigation, à l'adresse IP et aux données de connexion sont conservées trois (3) mois ;</li>
              <li>Les informations nécessaires dans le cadre des cookies sont conservées treize (13) mois au maximum.</li>
            </ul><br/>
        Les informations demandées lors de la création du Compte personnel ont un caractère obligatoire (ex : l'adresse mail pour la validation du Compte personnel).<br/><br/>
        L’Administration ne pourra pas traiter la demande de l’Usager en cas de défaut de renseignement des mentions obligatoires (avec un *) sur le formulaire.<br/><br/>
        Dans le respect des dispositions légales applicables en Principauté de Monaco, l’Usager dispose d'un droit d'accès aux informations nominatives le concernant, un droit d'opposition ainsi que le droit de demander à ce que soient rectifiées, mises à jour ou supprimées les données inexactes, incomplètes ou périmées.<br/><br/>
        L’Usager est également informé qu'il peut retirer son consentement à tout moment.<br/><br/>
        Pour exercer ses droits ou pour toute question sur le traitement de ses Données personnelles dans le cadre de MonGuichet.mc, l’Usager peut former une demande écrite à l'adresse mail suivante : <a class="text-interaction underline" href="mailto:mesdonnees{'@'}gouv.mc" target="_blank">mesdonnees{'@'}gouv.mc</a>, ou par voie postale à La Direction des Services Numériques, Immeuble Les industries, 2 Rue du Gabian, MC 98000 MONACO, en précisant l'objet de la demande, ainsi que son nom, prénom et adresse mail.<br/><br/>
        L’Usager peut également adresser une demande à l'Administration <a class="text-interaction underline" href="https://contacts.gouv.mc/contactez-monguichet" target="_blank">en cliquant ici</a>.<br/><br/>
        Pour veiller à la confidentialité de la réponse et nous assurer de répondre uniquement à la personne sujet des données, un justificatif d'identité, en noir et blanc, pourra être demandé au demandeur.<br/><br/>
        Si la personne qui a exercé ses droits estime, après avoir contacté l'Administration, que ses droits n'ont pas été respectés, elle peut introduire une réclamation auprès de la Commission de Contrôle des Informations Nominatives : <a class="text-interaction underline" href="https://www.ccin.mc" target="_blank">www.ccin.mc</a>.`,
    },
    favorableOpinion: {
      title:
        '10. AVIS FAVORABLE DE LA COMMISSION DE CONTROLE DES INFORMATIONS NOMINATIVES',
      description: `Le présent Portail, dénommé « MonGuichet.mc » a reçu l'avis favorable de la Commission de Contrôle des Informations Nominatives, par la délibération n° 2022-63 du 20 avril 2022. Il a fait l'objet d'une décision le 20 avril 2022 de SEM le Ministre d'État portant sur la mise en œuvre, par la Direction des Services Numériques, de la gestion du compte permettant aux usagers d'entreprendre et de suivre des démarches par téléservices, parue au <a class="text-interaction underline" href="https://journaldemonaco.gouv.mc/Journaux/2022/Journal-8589" target="_blank">Journal de Monaco</a> le 06 mai 2022.`,
    },
    falseStatement: {
      title: '11. DECLARATION FAUSSE',
      description: `L'Usager déclare sur l'honneur connaître parfaitement les sanctions prévues aux articles 90 et suivants du Code pénal monégasque en cas de fausse déclaration, soit une peine d'emprisonnement de trois (3) mois à un (1) an et l'amende prévu au chiffre 2 de l'article 26 ou l'une de ces deux peines seulement.`,
    },
    hyperlinks: {
      title: '12. LIENS HYPERTEXTES',
      description: `La création de liens hypertextes vers MonGuichet.mc n'est soumise à aucune autorisation préalable de l'Administration et est autorisée pour tout support, à l'exception de ceux diffusant des informations à caractère polémique, pornographique, xénophobe ou pouvant, dans une plus large mesure porter atteinte à la sensibilité du plus grand nombre. La mention explicite de l'Etat de Monaco dans l'intitulé du lien est due. L'Administration se réserve le droit de demander la suppression de tous les liens existants qui contreviendraient à l'objet de MonGuichet.mc.<br/><br/>
      MonGuichet.mc peut contenir des liens hypertextes vers des sites externes. L'Administration ne contrôle pas ces sites et ne peut en aucun cas être tenue responsable, de quelque manière que ce soit, du contenu publié sur ces sites externes.<br/><br/>
      L'Administration ne peut être tenue responsable des dommages ou pertes qui seraient consécutives ou en relation avec l'utilisation de ces sites externes. Les Usagers du Portail sont pleinement responsables et supportent les risques associés à l'utilisation de ces sites externes. Aucune responsabilité ne pourra être recherchée auprès de l'Administration.<br/><br/>
      Tout Usager qui utilise MonGuichet.mc est invité à contacter l'Administration <a class="text-interaction underline" href="https://contacts.gouv.mc/contactez-monguichet" target="_blank">ici</a> en cas de dysfonctionnement d'un lien.`,
    },
    competentJurisdiction: {
      title: '13. JURIDICTION COMPETENTE',
      description: `Les présentes Conditions générales d’utilisation sont régies par la loi monégasque.<br/><br/>
      A défaut de règlement amiable, en cas de litige relatif à l’interprétation, la validité ou l’exécution des CGU et faute d’être parvenus à un accord amiable, les Usagers donnent compétence expresse et exclusive aux tribunaux compétents de la Principauté de Monaco, nonobstant pluralité de défendeurs.`,
    },
  },
  legalNotice: {
    linkTitle: 'Mentions légales',
    title: 'Mentions Légales',
    description:
      'Le présent Portail, ci-après dénommé « MonGuichet.mc » est exploité par <strong class="font-semibold">l’Etat de Monaco</strong>, ci-après dénommée « l’Administration », dont le siège est situé à au Ministère d’Etat, 2 place de la Visitation, 98000 Monaco.',
    publisherLabel: 'Editeur',
    publisher:
      'Secrétariat Général du Ministère d’Etat</br>Place de la visitation</br>MC 98000 MONACO',
    directorOfPublicationTitle: 'Directeur de la publication',
    directorOfPublicationBreakdown:
      "Direction des Services Numériques</br>Mr. Julien DEJANOVIC</br>2 rue du Gabian</br>Immeuble 'Les industries'</br>MC 98000 MONACO",
    contactLabel: 'Contact',
    contact:
      "Téléphone : (+377) 98 98 40 26</br>Adresse postale : 2 rue du Gabian, Immeuble 'Les Industries', BP 673, MC 98014 MONACO CEDEX</br>Formulaire de contact : <a class='text-interaction underline' href='https://contacts.gouv.mc/contactez-monguichet' target='_blank'>https://contacts.gouv.mc/contactez-monguichet</a>",
    hostingLabel: 'Hébergement',
    hosting:
      'MonGuichet.mc est hébergé par la société Monaco Télécom, au capital de 1.687.640 euros, immatriculée au RCI de Monaco sous le N° 97 S 03277, dont le siège social est situé 4-6 avenue Albert II – 98000 MONACO.',
  },
  cookies: {
    linkTitle: 'Cookies',
    title: 'Politique Cookies',
    description: `Les « cookies » correspondent à une suite d'informations, généralement de petite taille, identifiés par un nom, qui peuvent être transmis à votre navigateur par un site web sur lequel vous vous connectez. Ainsi, le cookie vient enregistrer des informations relatives à votre navigation sur un site.<br/></br>
    Le Portail recourt à des cookies dans le but d’assurer le bon fonctionnement et la disponibilité du présent Portail afin d’optimiser votre navigation.`,
    necessary: {
      title: `1. Les cookies strictement nécessaires au fonctionnement du Portail « MonGuichet.mc »`,
      description: `Ces cookies sont indispensables afin de poursuivre la navigation sur le Portail.</br></br>Ils permettent la poursuite des fonctionnalités principales du Portail et la sécurisation de la connexion de l’Usager.<br/></br>
        Ci-dessous, un tableau récapitulatif concernant l’ensemble des précisions sur les différents cookies strictement nécessaires utilisés sur le Portail « MonGuichet.mc ».`,
      cookieName: `Nom du cookie`,
      service: `Nom de domaine`,
      purpose: 'Finalités',
      validity: 'Durée de conservation',
      storageTime: 'Supprimé lorsque vous quittez la session de navigation.',
      'XSRF-TOKEN': {
        service: 'Cookie pour la protection CSRF du FRONT',
        purpose: 'Protection contre le « Cross site request forgery »',
      },
      _csrf: {
        service: 'Cookie pour la protection CSRF du FRONT',
        purpose: 'Protection contre le « Cross site request forgery »',
      },
      refreshToken: {
        service: 'Cookie Keycloak',
        purpose:
          'Cookies contenant un token permettant le rafraichissement du token',
      },
      refreshTokenExpiration: {
        service: 'Cookie Keycloak',
        purpose:
          'Définis le temps d’expiration du refresh token fournis par Keycloak',
      },
      tokenKeycloak: {
        service: 'Cookie Keycloak',
        purpose: 'Token fournis par Keycloak pour l’authentification',
      },
      tokenExpiration: {
        service: 'Cookie Keycloak',
        purpose: 'Définis le temps d’expiration du token fournis par Keycloak',
      },
      strategy: {
        service: 'Cookie Keycloak',
        purpose:
          'Permet à Keycloak de comprendre quel type d’authentification est utilisé',
      },
      i18n_redirected: {
        service:
          'Cookie i18n (librairie de traduction et de gestions des langues)',
        purpose:
          'Permet au navigateur d’aligner la langue du navigateur et la langue de MonGuichet.mc. Permet aussi de gérer les langues au sein de MonGuichet.mc. Permet aussi la traduction sur MonGuichet.mc',
      },
    },
    analytics: {
      title: '2. Les cookies de mesures d’audience',
      description: `Des cookies d’audience ou statistiques peuvent également être installés afin d’améliorer nos services en établissant des statistiques, par exemple selon le volume de fréquentation et d’utilisation des divers éléments composant MonGuichet.mc (rubriques et contenus visités, parcours) pour en améliorer l’intérêt et l’ergonomie.<br/>
        Dans le cadre de MonGuichet.mc, les cookies de mesures d’audience déposés sur le terminal de l’internaute permettent de produire des statistiques anonymes. En effet, l’adresse IP de l’internaute est anonymisé aux 2 derniers octets.`,
      cookiesName: `Commencent par :
        <ul class="list-disc list-inside">
         <li>_pk_ref</li>
         <li>_pk_cvar</li>
         <li>_pk_id</li>
         <li>_pk_ses</li>
        </ul>`,
      service: 'Matomo',
      purpose: `Cet outil a pour finalité la mesure d’audience du portail (adresse IP (avec anonymisation des 2 derniers octets) : pages visitées et leur nombre, nombre d’affichage par page, durée passée sur chaque page, nombre de clics, nom et version du navigateur web de l’internaute, système d’exploitation de l’Usager, horodatage d’accès, nombre et nom des documents téléchargés`,
      storageTime: '13 mois',
    },
    managing: {
      title: '3. Gestion des cookies',
      description: `La gestion des cookies est libre et engage l’Usager. Certaines parties du Site peuvent ne plus fonctionner de manière optimale après désactivation de certains cookies. L’Administration ne pourra en être tenue responsable.<br/><br/>
      À tout moment, l’Usager peut revenir sur ses choix et son consentement concernant la gestion des cookies déposés sur son poste, en modifiant les paramètres de son navigateur, comme précisé ci-dessous.<br/><br/>
        En outre, l’Usager est informé qu’il peut également procéder de la manière suivante :
        `,
      deletion:
        '<strong>Suppression des cookies</strong> : il est possible de supprimer tous les cookies se trouvant déjà sur votre appareil en effaçant l’historique de navigation de votre navigateur. Ainsi, tous les cookies des différents sites que vous avez pu visiter seront supprimés et des informations sauvegardés (identifiants de connexions, par exemple) pourront disparaître.',
      gestion:
        '<strong>Gestion des cookies</strong> : il vous suffit de consulter les paramètres de votre navigateur propre à la gestion des cookies et de la protection de la vie privée. Vous pouvez à tout moment modifier les paramètres de votre navigateur pour gérer ou bloquer les cookies. Pour plus d’informations, nous vous invitons à vous référer aux politiques de gestion des cookies de votre navigateur :',
      firefox: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences" target="_blank">Firefox</a>`,
      safari: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="https://support.apple.com/fr-fr/HT201265" target="_blank">Safari</a>`,
      chrome: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en" target="_blank">Chrome</a>`,
      opera: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank">Opera</a>`,
      ccin: '<a class="text-interaction underline" href="https://www.ccin.mc/fr/fiches-pratiques/bonnes-pratiques-en-matiere-de-sites-internet" target="_blank">CCIN</a>',
      cnil: '<a class="text-interaction underline" href="https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur" target="_blank">CNIL</a>',
      complementaryInfos:
        'Pour toute information complémentaire sur les cookies, vous pouvez consulter les pages suivantes :',
    },
  },
  contact: {
    title: 'Contact',
    link: 'https://contacts.gouv.mc/contactez-monguichet',
  },
  register: {
    emailAlreadyExistsText:
      'L’adresse email de contact {email} est déjà associée à un compte existant',
    explanationFirst: `Nous avons détecté qu'il existe déjà un ou plusieurs comptes associés à l'adresse e-mail de contact <b>{email}</b>.`,
    explanationSecond:
      "Pour retrouver l'historique de vos démarches, connectez-vous avec votre compte existant.",
    connect: 'Me connecter',
    findIdentifier: 'Retrouver mon identifiant',
    ignore: 'Ignorer, continuer la création de compte',
  },
}
