export default {
  changeLang: 'Change language',
  back: 'Back',
  header: {
    profile: 'My profile',
    homeLink: 'Homepage',
    authentication: 'Authentication',
    myServicesLink: 'My procedures',
    onlineServicesLink: 'Online services',
    appointmentsLink: 'My appointments',
    documentsLink: 'My documents-holder',
    logout: 'Logout',
    navigationLabel: 'Navigation',
    publicServiceIndividuals: 'Public Services for Individuals',
    publicServiceBusinesses: 'Public Services for Businesses',
    individuals: 'Individuals',
    businesses: 'Businesses',
    searchPlaceholder: 'Search for an online service',
    documentSearchPlaceholder: 'Search for a document',
    popup: {
      mainText:
        'To access the {userTypePopup}  area, you will be logged out. <br/> You will then be able to log in using your {userTypePopup} usernames (or create an account). \n',
      logout: 'Log out?\n',
      wouldYouLikeToLogout: ' Would you like to log out?\n',
    },
    accessMonGuichet: 'Access MonGuichet.mc {userTypePopup}',
    partnersLabel: 'See all linked websites',
    partnersActionLabel: 'OuvertureSitesLiés',
    partnersCloseButtonActionLabel: 'Fermeture',
    partnersLink:
      'https://en.gouv.mc/Portail-du-Gouvernement/Policy-Practice/Related-sites',
    infosLabel:
      'All of the useful services and information for users in the Principality on the official websites of the Prince’s Government of Monaco',
    certifiedWebsiteLabel: 'Official website of the Principality of Monaco',
    userAccountLabel: 'Account: ',
    metanav: {
      governmentSiteHref: 'https://en.gouv.mc/Portail-du-Gouvernement',
      governmentSiteLabel: 'The Prince’s Government of Monaco website',
      monGuichetHref: 'https://monguichet.mc/',
      monGuichetLabel: 'The portal, where you can access online services',
      myPublicServiceHref: 'https://monservicepublic.gouv.mc/en',
      myPublicServiceLabel: 'All services and informations for individuals',
      myCompanyServiceHref: 'https://monentreprise.gouv.mc/en',
      myCompanyServiceLabel: 'All services and informations for businesses',
    },
  },
  footer: {
    legal: 'Legal notice',
    cookies: 'Cookies',
    contact: 'Contact',
    tou: 'TOU',
    copyright:
      'All rights reserved - Monaco {prodYear} - {currentYear} - {appVersion}',
    socialNetwork: 'Follow us on social networks',
    partnersLabel:
      'Follow this link to find all partner sites linked to the Government',
    partnersLink: 'https://www.google.fr/',
    governmentLink: 'https://en.gouv.mc/',
    infosLabel:
      "Find all information and services on the websites of the Prince's Government of Monaco",
  },
  login: {
    login: 'Log in',
  },
  signup: {
    pageTitle: 'Create an account',
    titleBusinesses: 'Create a Business account',
    backToAccountChoice: 'Back',
    titleIndividuals: 'Create an Individual account',
    address: 'My address',
    identifyWithMconnect: 'Identify yourself with MConnect',
    loginWithMconnect: 'Log in with MConnect',
    chooseUsernames: 'Choose your usernames',
    usernamePlaceholder: 'Ex : johndoe06',
    alreadyHaveAnAccount: 'I already have an account',
    haveVisa: 'I am the holder of a residence permit',
    checkYourMailbox: 'Check your mailbox',
    finalize:
      'To complete your registration, please click on the  <b>activation link</b> that was sent to your email address.',
    finish: 'Finish',
    pleaseAcceptTerms:
      'To use this service, please accept the <a href="{page}" target="blank" class="text-interaction">Terms of Use</a>.',
    iAcceptTerms:
      'I have read and accept the <a class="text-interaction" href="{termUrl}" target="blank">Terms of Use</a> and that my personal data will be processed in the context of MonGuichet.mc.',
    socialReason: 'Registered name',
    NISNumber: 'NIS (Monaco Statistics Business ID) number',
    CARNumber: 'CAR (Independent Pensions Fund) number ',
    RCINumber: 'RCI (Trade and Industry Registry) number ',
    socialReasonTooltip:
      'Name of the business established when it was started and which is listed in the Trade Registry',
    NISNumberTooltip:
      'Statistical Identification Number allocated by Monaco Statistics when a business is started.\n',
    NISNumberTooltip2:
      'The NIS comprises four digits, one letter then five digits.',
    CARNumberTooltip:
      'Employer number supplied by the Monaco Social Security Funds',
    RCINumberTooltip:
      'Registration number in the Trade and Industry Registry.\n',
    RCINumberTooltip2:
      'The RCI is made up of two digits indicating the year, the letter “P” or “S”, and five digits representing the registration number.',
    optionalInfos:
      'This information is optional, you can complete it later in your profile.',
    socialReasonPlaceholder: 'Ex : MyBusiness',
    NISNumberPlaceholder: 'Ex : 1234A56789',
    CARNumberPlaceholder: 'Ex : CAR',
    RCINumberPlaceholder: 'Ex : 09P12345',
  },
  home: {
    subtitle:
      'Find the procedures that can be carried out online and track your requests',
    mainMessage:
      "The <span class='text-red_individual'>online services</span> portal of the Prince's Government and Monaco City Hall",
    mainMessagePro:
      "The <span class='text-brand_blue_secondary'>online services</span> portal of the Prince's Government and Monaco City Hall",
    followOngoingProcedures: 'Follow my procedures',
    followMyAppointments: 'My appointments',
    findOnlineService: 'Find an online service',
    fastAccesses: 'Fast accesses',
    myActivity: 'My activity',
    onlineServices: 'Online services',
    myFavourites: 'My favourites',
    survey: {
      title: 'Share your views!',
      textIndividuals: `Help us to improve your personal area on MonGuichet.mc.`,
      textProfessionals: `Help us to improve the MonGuichet.mc personal area for business users.`,
      surveyButtonLabel: 'Complete the survey',
    },
    cards: {
      myProcedures: 'My procedures',
      myAppointments: 'My appointments',
      documentsHolder: 'My documents-holder',
    },
    mconnectCard: {
      description:
        'Thanks to your Monegasque <span class="text-red_individual">Digital Identity</span> :',
      followAppointments: 'View appointments',
      secureConnection: 'Log on safely',
      simplifyMyProcedures: 'Simplify your online services',
      documentsHolder: 'Benefit from a documents-holder',
      askCivilStatusCertificates:
        'Request a civil registration or nationality certificate',
      useMConnect: 'Use',
    },
    mconnect:
      'From now on, <strong class="font-semibold">you must use MConnect</strong> to access your Individual account.',
    individuals: 'individuals',
    professional: 'professional',
    revocation: 'Fill in your digital identity information.',
    revocationActionLabel: 'Fill in my information',
  },
  actions: {
    modify: 'Modify',
    cancel: 'Cancel',
    confirm: 'Confirm',
    continue: 'Continue',
    yes: 'Yes',
    no: 'No',
    previous: 'Previous',
    next: 'Next',
    select: 'Select',
    selectDocumentType: 'Select a type',
    search: 'Search',
    addDocument: 'Add a document',
    filter: 'Filter',
    deleteFilters: 'Reset the filters',
    keepDocument: 'Keep the document',
    keepDocuments: 'Keep the documents',
    delete: 'Delete',
    deleteSelection: 'Delete | Delete the documents',
    mobileDeleteSelection: 'Delete {count} document | Delete {count} documents',
    download: 'Download',
    downloadSelection: 'Download | Download the documents',
    mobileDownloadSelection:
      'Download {count} document | Download {count} documents',
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    apply: 'Apply',
    terminate: 'Terminate',
    retry: 'Retry',
  },
  errors: {
    usernameContainsWhitespaces: 'The username must not contain any spaces.',
    usernameDoesNotRespectPosixStandard:
      'Only alphanumeric characters, periods and dashes can be used',
    addressMustBeValid: 'The address is incorrect',
    usernameFirstCharacterMustBeALetter: 'The first character must be a letter',
    mandatoryField: 'This field is mandatory',
    invalidEmail:
      'This email address is incorrect or you do not have any activated account',
    incorrectEmail: 'This email address is incorrect',
    emailsMustMatch: 'The email address and its confirmation must be identical',
    incorrectPassword: 'The password is incorrect',
    passwordsMustMatch: 'The password and its confirmation must be identical',
    mustNotContainANumber: 'This field must not contain a number',
    minLength: 'This field must contain at least {limit} characters.',
    minPasswordLength: 'The password must contain at least {limit} characters.',
    passwordShouldContainLowercaseLetter:
      'The password must contain at least one lowercase letter.',
    passwordShouldContainUppercaseLetter:
      'The password must contain at least one uppercase letter.',
    passwordShouldContainANumber:
      'The password must contain at least one number',
    passwordShouldContainASpecialCharacter:
      'The password must contain at least one special character',
    maxLength: 'This field must contain at max {limit} characters.',
    password: {
      mustContain: 'The password must contain: ',
      length: '9 characters min',
      uppercase: 'at least 1 capital letter',
      lowercase: 'at least 1 lower case letter',
      number: 'at least 1 number',
      special: "at least 1 special character among () ? _'; : {'@'} # & * + =",
    },
    usernameAlreadyTaken:
      'Existing login. If you have already created an account on the Public Service website, log in with your usual username.',
    expectedDateFormat: 'The expected format is DD/MM/YYYY',
    dateMustBeBeforeToday: 'The date must be earlier than the current date',
    somethingWentWrong: 'Something went wrong...',
    emailSameAsOld: 'This e-mail address is the same as before.',
    wrongCredential:
      'Incorrect Username and/or Password. Reminder: If you have already used MConnect to access your Individual account, please login with MConnect only.',
    numericIdIsOnlyForIndividuals:
      'The digital identity is only available for individuals, we are not able to link your digital identity to a Business account.',
    captchaError: 'The captcha could not be verified',
    invalidFileSize: 'The selected file size is greater than {limit} Mo',
    invalidFileExtension: 'The selected file extension is invalid',
  },
  forgotIdentifier: {
    title: 'Forgotten your username?',
    hint: 'To retrieve your username, please enter the email address you used when you created your account.',
    confirmationTitle: 'Check your mailbox',
    sent: 'Your username has been sent to you by email.',
  },
  profile: {
    profile: 'Your profile',
    account: 'My account',
    usernames: 'My usernames',
    username: 'Username',
    password: 'Password',
    confirmPassword: 'Re-enter password',
    aboutMe: 'About me',
    homeAddress: 'My home address',
    companyAddress: 'Your company address',
    myPersonalInfo: 'My personal information',
    myInfo: 'My information',
    companyInfo: 'Business information',
    email: 'Email Address',
    modifyEmail: 'Modify the e-mail address',
    modifyPassword: 'Modify the password',
    identifier: 'Username',
    technicalIdentifier: 'Account',
    emailConfirmation: 'Re-enter email address',
    contactEmail: 'Contact email address ',
    login: 'Log in',
    identity: 'My identity',
    contactInfos: 'My contact informations',
    address: 'My address',
    company: 'My company',
    personalInfos: {
      surname: 'Name of use',
      MconnectSurname: 'Birth name',
      firstName: 'First Name',
      secondName: 'Second name',
      thirdName: 'Third name',
      nationality: 'Nationality',
      civility: {
        civility: 'Title',
        madam: 'Madam',
        sir: 'Sir',
        modify: 'Modify my civility',
      },
      residencePermitHolder: 'Residence permit holder',
    },
    aboutMyCompany: {
      aboutMyCompany: 'Business information',
      socialReason: 'Registered name',
      nisNumber: 'NIS (Monaco Statistics Business ID) number',
      carNumber: 'CAR (Independent Pensions Fund) number ',
      rciNumber: 'RCI (Trade and Industry Registry) number ',
    },
    addressInfos: {
      streetAddress: 'Residence address',
      companyStreetAddress: 'Company address',
      additionalAddress: 'Address complement',
      secondAdditionalAddress: 'Second address complement',
      additionnalAddressPlaceholder:
        'Company, building, appart., floor, PO box, ...',
      postalCode: 'Postal Code',
      city: 'City',
      country: 'Country',
      additionalAddressLabel: 'Additional address details',
    },
    changeEmail: {
      title: 'Change your email address',
      enterPassword: 'First, please enter your current password',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Password',
      enterEmail: 'Enter your new email address',
      emailLabel: 'New email address',
      confirmEmailLabel: 'Re-enter new email address',
      success: 'Your email has been changed successfully',
      confirmationSentence: 'A confirmation e-mail has been sent to you.',
      backToProfile: 'Return to my profile',
    },
    confirmEmail: {
      title: 'Check your email address',
      text: 'To activate your new email address, </br> please click on the activation link that was sent to the email address you provided.',
    },
    unsubscribe: 'Unsubscribe',
    unsubscribePopup: {
      body: 'If you wish to unsubscribe, you can send a message by clicking here:  <a class="text-interaction cursor-pointer" href="https://contacts.gouv.mc/en/contact-monguichet" target="blank">https://contacts.gouv.mc/en/contact-monguichet</a> indicating your login and your contact email address.',
      title: 'Unsubscribe?',
      footer:
        'If you unsubscribe, you will no longer be able to log in with this account, and you will be unsubscribed from all the online services to which you have subscribed.',
    },
    digitalIdentity: {
      verifiedBy: 'Verified by',
      migrateToTitle: 'Migrate to the digital identity',
      migrateToLabel:
        'Que vous soyez monegasque ou résident, passez à l’identité numérique pour bénéficier de services inédits et réduire vos nombres d’identifiants et de mots de passe !',
      useMConnect: 'Use MConnect to log in to MonGuichet',
      getMConnect: 'Get the digital identity',
      title: 'My Digital Identity',
      name: 'Birth name',
      nameUse: 'Name of use',
      firstName: 'First name(s)',
      nationality: 'Nationality',
      youAre: 'You are',
      birth: 'Date of birth',
      cityOfBirth: 'City of birth',
      countryOfBirth: 'Country of birth',
      registrationAuthority: 'Registration Authority',
      resident: 'Resident',
      nonResident: 'Non Resident',
      monagasque: 'Monegasque',
      policeDepartment: 'Police Department',
      cityHall: 'Monaco City Hall',
      digitalServicesDepartment: 'Digital Services Department',
      information:
        'Is this information wrong? Contact Digital Identity Support by clicking here !',
    },
    digitalIdentityRegister: {
      title: 'Finalise creating your account',
      subtitle: 'Please fill in your address to finalise creating your account',
      addressLabel: 'Address',
      addressPlaceholder: 'Ex : 4, allée des roses',
      additionnalAddressPlaceholder: 'Additional address details',
      zipCodeLabel: 'Postal Code',
      zipCodePlaceholder: 'Ex : 98000',
      cityLabel: 'City',
      cityPlaceholder: 'Ex : Monaco',
      countryLabel: 'Country',
      select: 'Select',
      button: 'Create my account',
    },
    fromProcedure: {
      backToProcedure: 'Return to my procedure',
      warning:
        'You have commenced an online procedure. You can change the information you have entered below, and can then continue with this procedure.',
    },
    useMconnectBox: {
      title: 'Switch to digital identity',
      content:
        '<span class="font-semibold">Monegasque citizens and residents</span>: use new services with fewer usernames and passwords!',
      button: 'Use MConnect',
      linkLabel: 'Obtain a digital identity',
      link: 'https://mconnect.gouv.mc/decouvrir',
    },
    useMconnectPage: {
      route: '/use-mconnect',
      title: 'Use MConnect to log on to MonGuichet',
      alertMessage:
        'You will <strong>link your MonGuichet.mc account</strong> to your Digital Identity.<br/>You will then <strong>use MConnect to log in</string> to online services',
      youMust: 'To use MConnect, you must:',
      step1: '1. Activate your Monegasque Digital Identity',
      info1: 'How to obtain and activate your digital identity',
      step2: '2. Install the MConnect mobile app on your smarphone',
      info2: 'How to install MConnect Mobile',
      connectBtn: 'Log on using MConnect',
      installLink: 'https://mconnect.gouv.mc/utiliser/installer-sur-mobile',
    },
    revocation: {
      notCompleted: {
        title: 'Informations to be filled in',
        announcement:
          'Some information needed to secure your account is missing',
        subtitle:
          '<span class="font-semibold">Fill in your digital identity informations</span> so that :',
        explanation1:
          'You can be notified regarding renewal of the use of your digital identity',
        explanation2:
          'In the event that your card is lost or stolen, your digital identity can be blocked',
        actionLabel: 'Fill in my information',
        knowMore: 'Learn more at mconnect.gouv.mc',
      },
      completed: {
        title: 'Completed information',
        announcement: 'Your account information is up to date',
        subtitle: 'You can now:',
        explanation1: 'Update your contact details if they change',
        explanation2:
          'In the event that your card is lost or stolen your digital identity can be blocked',
        actionLabel: 'Access the service',
        knowMore: 'Learn more at mconnect.gouv.mc',
      },
    },
  },
  services: {
    title: 'My procedures',
    followMyServicesTitle: 'See all my procedures',
    subtitle: 'You can find your realised procedures here',
    updated: 'Mise à jour',
    accessTo: 'Go to',
    update: 'Update',
    categories: 'Filters',
    request: 'request | requests',
    beingProcessed: 'In process',
    beingProcessedFullLabel: 'Being processed',
    waitingForUserRequest: 'Awaiting',
    waitingForUserRequestFullLabel: 'Awaiting action',
    treated: 'Treated',
    refused: 'Refused',
    completedRequest: 'Terminated',
    delete: 'Delete',
    showResults: 'Show results',
    informations: {
      title: 'Were you unable to trace your application online?',
      paperApplication: 'You have made an application on paper:',
      paperApplicationExplanation:
        ' We do not yet propose the tracking of the applications which have not been made online.',
      onlineApplication: 'You have made an application online:',
      onlineApplicationExplanation:
        'We have not yet linked all the procedures to MonGuichet.mc.',
      onlineApplicationTracking:
        'You can track it directly in the relevant Online Service.',
      missingOnlineApplication: 'Has your request disappeared?',
      missingOnlineApplicationExplanation:
        'Your request may have been deleted if it had reached its maximum retention period.',
    },
    announcementLabel:
      'You have an application that requires your attention. Please provide additional information so that they can be processed. | You have applications that require your attention. Please provide additional information so that they can be processed.',
    noServices: {
      title: 'No pending online procedures',
      description:
        'Only the procedures you have realised <span class="font-semibold">online</span> will be displayed <span class="font-semibold">here</span>.',
      action: 'Find an online procedure',
    },
  },
  error: {
    page404: {
      title: 'The page requested does not exist',
      subtitle: 'The page does not exist or is no longer available (error 404)',
      back: 'Return to the home page',
      contact: 'Contact the site manager',
    },
    page500: {
      title: 'Something went wrong',
      subtitle: 'Internal server error (code 500)',
    },
  },
  onlineProcedures: {
    title: 'Online procedures',
    subtitle:
      'Here, you can find the procedures for individuals that you can carry out online',
    searchText: 'Look for an online procedure',
    categories: 'Filters:',
    results: 'result | results',
    tabs: {
      explore: 'Explore',
      myFavourites: 'My favourites',
    },
    noFavourites: {
      title: 'No favourites:',
      add: 'To add a favourite service:',
      item1: 'Click on the Explore tab',
      item2: 'Find the service',
      item3: 'Click on "..."',
      item4: 'Click on "Add to favourites"',
    },
    card: {
      consult: 'Understanding the process',
      access: 'Go to the online procedure',
      frenchAvailability: 'Only available in french',
      englandCode: 'EN',
      franceCode: 'FR',
      mconnectOnly: 'Service only available with ',
      mconnectOnlyFull: 'Use only MConnect to access this service',
      cityHallService: "Monaco City Hall's procedure",
      addToFavourites: 'Add to favourites',
      removeFromFavourites: 'Delete the favourite',
    },
    navigation: {
      title: "Didn't find what you were looking for?",
      servicePublicIndividuals: 'MonServicePublic',
      servicePublicCompany: 'MonEntreprise',
      notOnlineYet:
        'Your procedure may not be available on line yet. <br/>Find the procedure to be followed on {siteName}.gouv.mc website.',
      publicServiceButtonLabel: 'Go to {siteName}',
      scrollTop: 'Return to the top of the page',
    },
    noResults: {
      checkSpelling:
        'Check the spelling of the keywords and try changing some of the keywords (Ex : "car" instead of "cars").',
      notAvailable:
        'Your procedure may not be available on line yet. Find the procedure to be followed on {siteName}.gouv.mc website.',
    },
    availableServicesButtonLabel: 'Access to the procedures catalog',
    availableLanguages: 'Some online services are not available in English',
    deleteFavourite: {
      title: 'Delete a favourite',
      warning: 'Are you sure you want to delete this favourite ?',
      keep: 'Cancel',
      delete: 'Delete',
    },
    mconnectOnly: {
      title: 'Service accessible via MConnect',
      warning: 'To access this service, you must connect via MConnect.',
      description1:
        'You logged on to MonGuichet.mc with your username and password',
      description2:
        'If you already have a Monegasque digital identity, you can now use MConnect to log on to MonGuichet and access this service.',
      description3:
        'If you do not have a Monegasque digital identity, click on "obtain a digital identity".',
      useMyNumericIdentity: 'Use my digital identity',
      getANumericIdentity: 'Obtain a digital identity',
      cancel: 'Cancel',
    },
  },

  appointments: {
    title: 'My appointments',
    subtitle: 'You can find your booked appointments here',
    next: 'Next appointment',
    bookAnAppointment: 'Book an appointment',
    subheader: {
      incoming: 'Incoming',
      passed: 'Passed',
    },
    followingCard: {
      incoming: 'Incoming appointments',
      passed: 'Passed appointments',
      seeAll: 'See all my appointments',
    },
    description: {
      incoming: 'incoming appointments :',
      noneIncoming: 'No incoming appointments',
      passed: 'passed appointments :',
      nonePassed: 'No passed appointments',
    },
    noAppointments: {
      incomingTitle: 'No incoming appointments',
      passedTitle: 'No passed appointments',
      description:
        'Only appointments you have booked <span class="font-semibold">online</span> will be displayed <span class="font-semibold">here</span>',
    },
    notFound: {
      title: 'Can’t find your appointment details ?',
      explanation1:
        'Appointments made online with the Residency Section of the Police Department and the Driver and Vehicle Licensing Office now appear here. Not all appointment services have yet been connected to MonGuichet.mc.',
      explanation2:
        'To manage your appointment, refer directly to the confirmation email or SMS you received.',
      explanation3:
        'Your appointments are displayed in your online account <span class="font-semibold">for one month</span> after the appointment date, before being automatically deleted.',
    },
    dropdown: {
      headerLabel: 'Make an appointment',
      bodyContent:
        'You can book your appointments directly online with some government departments. Firstly, only some websites allow you to track appointments in your MonGuichet.mc personal area.',
    },
    modifyAppointment: 'Modify',
    vehiclePlateNumber: 'Plate Number :',
    addAppointment: 'Add to my agenda',
    reason: 'Reason :',
    with: 'With :',
    place: 'Place :',
    statuses: {
      pending: 'Awaiting action',
      scheduled: 'Scheduled',
      cancelled: 'Cancelled',
      declined: 'Refused',
      noShow: 'No show',
      completed: 'Completed',
      inProgress: 'Being processed',
    },
  },

  docHolder: {
    noDocument: {
      title: 'No document',
      description: 'Add documents from your computer or mobile phone.',
      explanationTitle: 'Use the documents-holder to:',
      explanationFirstStep:
        '<b>Add</b> documents often used for applications (<b>proof of address, proof of identity, etc.</b>) from MonGuichet or an online service',
      explanationSecondStep:
        '<b>Keep</b> all of your documents in one place in your personal area on MonGuichet.mc',
      explanationThirdStep:
        '<b>Re-use</b> the documents for all of your requests, <b>from any device</b>',
    },
    documentsDisplay: {
      count: 'selected document | selected documents',
      description:
        'You are responsible for the retention period of your documents. They will be stored while your MonGuichet.mc account remains active and your consent to the documents-holder feature is valid. You can use the documents stored here in your online services.',
      add: 'Added the:',
      sizeLabel: 'Size:',
      sizeUnit: 'Mo',
      format: 'Format:',
      type: 'Justificative type:',
      endOfValidity: 'End of validity:',
      file: 'Original file:',
      docInfos: 'Document’s Informations',
      select: 'Select a document to know more about it',
      filters: {
        filterBy: 'Filter by',
        byJustificativeTypeLabel: 'Filter by Justificative type',
        allType: 'All Justificative types',
        byAdditionDate: 'Sort by addition date',
        antechronologicalOrder: 'From latest to oldest',
        chronologicalOrder: 'From oldest to latest',
      },
      shouldDisplayMultipleSelection:
        'Activate the selection of multiple documents',
      activated: 'Activated',
      deactivated: 'Deactivated',
      noResults: 'No results',
    },
    addDocument: {
      title: 'Add a document',
      fileLabel: 'File',
      firstCondition: 'The file should not exceed {size} Mo.',
      secondCondition: 'Accepted formats: {types}',
      documentName: "Document's name",
      documentNamePlaceholder: 'ex : my_file_2023',
      documentType: 'Justificative type',
      endOfValidity: 'End of validity',
      endOfValidityDateFormat: 'DD/MM/YYYY',
      loadingLabel: 'Loading of your document ...',
      successLabel: 'The loading has been successful !',
      failureLabel: 'The loading failed !',
    },
    termsOfUse: {
      title: 'Data protection policy',
      subtitle: 'Using your MonGuichet documents-holder:',
      content1:
        'Subject to your consent (tick the box below), you can submit and store documents and authorise their recovery via an online service.',
      content2:
        'These documents may contain personal data. They are recorded and stored in your documents-holder on your MonGuichet.mc account by the Digital Services Department of the State of Monaco as data controller to facilitate your use of the online services.',
      content3:
        'You have the following rights: the right to access your data, the right to withdraw your consent at any time, and the right to request that inaccurate, incomplete, or inaccurate data be amended or deleted.',
      content4:
        'All information relating to the processing of your personal data is available in the <a href="{termsOfUseLink}" target="_blank" class="text-interaction underline">Terms Of Use</a>.',
      legal:
        'I consent to my documents being processed by the documents-holder service<span class="text-red">*</span>',
      actionConsent: 'Accept',
      actionDeny: 'Refuse',
    },
    modifyDocument: {
      title: 'Modify a document',
    },
    deleteDocument: {
      title: 'Delete a document',
      warning: 'Do you confirm the deletion of this document ?',
      description: 'It will not appear anymore in your documents-holder',
      loadingLabel: 'Deletion of your document ...',
      successLabel: 'The deletion has been successful !',
      failureLabel: 'The deletion failed !',
    },
    massDeleteDocument: {
      title: 'Delete the selected documents',
      warning: 'Do you confirm the deletion of these documents ?',
      description: 'They will not appear anymore in your documents-holder',
      loadingLabel: 'Deletion of your documents ...',
    },
    documentDetails: {
      title: "Document's details",
    },
    deleteDocHolder: {
      action: 'Delete my documents-holder',
      confirmation: 'Are you sure you want to delete your documents-holder?',
      warning:
        'Your consent for the documents-holder and the stored files will be automatically deleted',
      keep: 'Keep my documents-holder',
      delete: 'Delete',
      loadingLabel: 'Deletion of your documents-holder ...',
      successLabel:
        'The deletion of your documents-holder has been successful !',
      failureLabel: 'The deletion of your documents-holder failed !',
    },
    announcement: {
      warningDeactivation:
        'Your consent to use the documents holder will expire on: {date}.<br/>If you wish to continue using your documents holder, please renew your consent.',
      warningDeletion:
        'Without your consent, your documents will automatically be deleted on {date}.<br/>If you wish to retain the use of your documents holder, please renew your consent.',
      action: 'Renew my consent',
    },
  },
  head: {
    businesses: 'Businesses',
    individuals: 'Individuals',
    connecting: 'Connecting',
    register: 'Create an account',
    forgotIdentifier: 'Forgot Username',
    error: 'Error',
  },
  apiGichuni: {
    title: 'API specification',
  },
  porteDoc: {
    title: 'Porte doc test',
  },
  termsOfUse: {
    title: 'Terms of Use',
    headline: `These Terms of Use set out the terms and conditions for accessing and using this Portal “MonGuichet.mc"<br/><br/>
    The Administration reserves the right, at any time, to change, and/or to temporarily or permanently suspend all or part of this Portal.<br/><br/>
    These Terms of Use are valid for the entire duration of the Portal, until new Terms of Use replace the former.<br/><br/>
    Any use of the Portal after modification of the Terms of Use implies acceptance of the latter.<br/><br/>
    Terms of Use that appear online take precedence over any other version.<br/><br/>
    The User may opt to stop using the Portal at any time, but remains responsible for any prior use.<br/><br/>
    The User acknowledges that access to the Portal requires compliance with all of the instructions set forth in these Terms of Use.<br/><br/>
    These Terms of Use apply to all users accessing MonGuichet.mc.<br/><br/>
    Each User must unreservedly accept these Terms of Use when accessing MonGuichet.mc for the first time.<br/><br/>
    The User confirms that he/she has read and understood these Terms of Use in their entirety before using any of the Services offered by this MonGuichet.mc, and undertakes to comply with them.`,
    definition: {
      title: '1. Definitions',
      description:
        'The following words and phrases, beginning with a capital letter, whether used in the singular or plural, are employed herein with the meanings specified below:',
      administration: `<strong class='italic'>« Administration »</strong>: means the State of Monaco / The Monaco Prince’s Government / Digital Services Department;`,
      personalAccount: `<strong class='italic'>« Personal Account »</strong>: Account created by the User enabling access to MonGuichet.mc;`,
      termsOfUse: `<strong class='italic'>« Terms of Use » ou « TOU »</strong>: means these Terms and Conditions of Use;`,
      onlineServiceAccount: `<strong class='italic'>« Online Service Account »</strong>: the account used by the User to access the Administration’s Online Services before the roll-out of MonGuichet.mc;`,
      procedure: `<strong class='italic'>« Procedure »</strong>: describes all of the actions and interactions that an individual or business can engage in with Monaco’s Administration departments. Public Service procedures are available from the Public Service website <a class="text-interaction underline" href="https://monservicepublic.gouv.mc/en" target="_blank">monservicepublic.gouv.mc</a> and <a class="text-interaction underline" href="https://monentreprise.gouv.mc/en" target="_blank">monentreprise.gouv.mc</a>. Procedures which can be carried out online are those which can be carried out electronically using an Online Service;`,
      personalData: `<strong class='italic'>« Personal Data »</strong>: means any information relating to an identified or identifiable individual (“person concerned”). An “identifiable individual” is considered to be any individual who may be identified, directly or indirectly, including through reference to an identifying detail such as a name, an identification number, location data, an online username, or one or more attributes specific to his or her physical, physiological, genetic, psychological, economic, cultural or social identity;`,
      businesses: `<strong class='italic'>« Businesses »</strong>: refers to legal entities. Business Procedures are Procedures carried out by an individual acting in a professional capacity on behalf of a legal entity;`,
      userName: `<strong class='italic'>« Username »</strong>: a unique user name defined by the User when creating their Account (excluding the MConnect service). The user name allows Users to identify themselves and access MonGuichet.mc;`,
      mconnect: `<strong class='italic'>« MConnect »</strong>: the name of the technical authentication solution provided by the Digital Services Department (DSN). It enables Users to confirm their identity when using services provided by service providers and to access these services securely. Information about MConnect is available at <a class="text-interaction underline" href="https://mconnect.gouv.mc/en" target="_blank">mconnect.gouv.mc</a>. For the Terms of Use, please refer to each Online Service which makes use of MConnect;`,
      individual: `<strong class='italic'>« Individuals »</strong>: refers to natural persons. Individual Procedures are Procedures affecting everyday life that individuals carry out in a personal capacity;`,
      website: `<strong class='italic'>« Portal »</strong>: means the website « MonGuichet.mc », <a class="text-interaction underline" href="https://monguichet.mc/en" target="_blank">www.monguichet.mc</a>`,
      docHolder: `<strong class='italic'>« Document holder »</strong>: refers to the user's storage area available on the "MonGuichet.mc" Portal.
        <br/>The User may, if he/she has consented to its use:
                  <ul class="list-inside">
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Load/store a document in your document folder from the Portal;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Use a document from MonGuichet.mc document folder for an online procedure;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>Save a document from an online procedure to the MonGuichet.mc document folder.</li>
                  </ul>`,
      services: `<strong class='italic'>« Services »</strong>: refers to the functions offered by the Portal, in particular:
                  <ul class="list-inside">
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>A catalogue of Public Service Procedures that can be carried out online via online services;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>The ability to use the MConnect Service for identification purposes;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>The ability for Users to manage their profiles and update their information;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>A consolidated overview of Procedures carried out online via an Online Service, enabling the User to track the progress of their applications and requests;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>A consolidated overview of appointments, booked online via an online service, enabling MConnect or Professional users to track the status of their appointments;</li>
                    <li class="pl-5 flex flex-row items-center"><span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span>The document Folder allows the MConnect User to manage the storage and retrieval of documents required for the use of online services.</li>
                  </ul>`,
      onlineServices: `<strong class='italic'>« Online Service »</strong>: as defined in Sovereign Ordinance no. 3.413 of 29 August 2011 concerning various measures pertaining to the relationship between the Administration and its subjects, amended « […] <span class="italic">any information system that enables Users to complete Procedures, administrative formalities or payments electronically</span> » to which the Portal provides access.`,
      user: `<strong class='italic'>« User »</strong>: refers to any natural person accessing the Portal in order to carry out online procedures.`,
    },
    purpose: {
      title: '2. PURPOSE',
      description: `The purpose of these Terms of Use is to set out the terms and conditions for the issue and use of MonGuichet.mc Portal, the purpose of which is to improve the accessibility of the Administration’s online services.<br/><br/>
      From this Portal, Users can:
          <ul class="list-disc list-inside">
              <li>Log in, using their personal Account or the MConnect Service;</li>
              <li>Access the catalogue of Public Service Procedures that can be carried out online ;</li>
              <li>View a history of all Procedures they have completed online ;</li>
              <li>View a history of appointments made online;</li>
              <li>Keep their Individual and Business Procedures separate;</li>
              <li>Keep their Individual and Business meetings separate;</li>
              <li>Store documents that they deem necessary to complete their Procedures;</li>
              <li>Enable online services to retrieve documents from their document folder;</li>
              <li>Access Services from any digital location.</li>
          </ul><br/>
          Navigation between the various online services is made easier thanks to a single authentication system.`,
    },
    access: {
      title: '3. ACCES TO THE PORTAL',
      description: `Access methods to the Portal are strictly private and confidential.<br/><br/>
      With the exception of any connection costs applicable, access to the Portal is free of charge. Any connection costs are borne by the User and will not be refunded by the Administration.<br/><br/>
      At the time of use, the User is prompted to fill in an online form with all necessary precautions and safety measures.<br/><br/>
      The User undertakes to enter the aforementioned data under his/her sole responsibility, under his/her own control and direction, to provide complete, accurate and up-to-date information, and to refrain from using another person’s identity.<br/><br/>
      Should a User wish to notify the Administration of an error or correction, he/she is requested to do so at the following e-mail address: <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a><br/><br/>
      The Administration reserves the right to refuse without warning or compensation of any kind, illegal, abusive or repetitive appointments.`,
      userName: {
        title: '3.1 Access using Username',
        description: `MonGuichet.mc can be used following authentication of the User via a Personal Account, which must be created if they do not already have one, or do not wish to use an existing Account.<br/><br/>
        On their first use of the website, the User is invited to access their existing Online Services Account or to create one by completing an online form.<br/>
        To create a personal Account, the following must be provided: email address, title, surname(s), first name(s) and postal address. The User must treat this information with the appropriate precautions and security measures.<br/>
        The User must retain their user name and password, which they will need when accessing their Personal Account or the Online Services that they use.<br/><br/>
        To log in to the Personal Account, the User’s personal user name and password must be entered. If this is successful, the User will gain access to their Personal Account and can use all of the Services.<br/><br/>
        If the User forgets their personal user name or password, they must click on the “Forgot Password” link on the login page. The procedure for recovering their details will then be explained to them.<br/><br/>
        It should be noted that a password is personal and confidential and should never be shared. The personal password must not include all or part of the User’s user name, first name or surname. The User undertakes to change their password if they suspect that their personal account has been used fraudulently agrees never to store their password in unencrypted form.<br/><br/>
        The User must provide a valid email address when creating their Personal Account. This address is required and is used to confirm the operations carried out by the User.<br/><br/>
        The User undertakes to inform the Administration straight away of any unauthorised use of this information. The Administration cannot be held liable for any damages caused by the use of the username and password by an unauthorised third party.<br/><br/>
        The User who wishes to inform the Administration of a mistake or correction is asked to send an email to the following address: <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.`,
      },
      mconnect: {
        title: '3.2 Access using MConnect',
        description: `MonGuichet.mc can be accessed using the MConnect service. In this case, no specific user name or password is required for thePortal.<br/><br/>
          The first time they use the MConnect service on MonGuichet.mc, the User who already has an Online Services Account are invited to link this account to their MConnect authentication. By doing so, the User will be able to see the full history of procedures carried out and appointments booked online via online services using their Online Services Account.<br/><br/>
          To create a Personal Account, the following information must be provided: email address and postal address<br/><br/>
          The User must provide a valid email address when creating their Personal Account. This address is required and is used to confirm the operations carried out by the User.<br/><br/>
          The User undertakes to inform the Administration straight away of any unauthorised use of this information. The Administration cannot be held liable for any damages caused by the use of the MConnect service by an unauthorised third party.<br/><br/>
          Users who wish to inform the Administration of a mistake or correction are asked to send an email to the following address: <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.`,
      },
    },
    dataRetention: {
      title: '4. DATA RETENTION',
      personalAccount: {
        title: '4.1 Personal account',
        description: `Personal accounts which have not been used for more than three (3) years will be automatically deleted. An email will warn the user informing them of their forthcoming deregistration and informing them of the possibility of reconnecting within a period of one month in order to avoid, if they so wish, this deregistration. The User can also request deletion of their Account at any time by emailing: <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.<br/><br/>
          Deregistration leads to the permanent deletion of all personal data and all documents stored in the User's document folder within ten (10) days.<br/><br/>
          Failure to renew the User's consent to access the document folder feature will result in the permanent deletion of all documents stored on the User's document folder within one (1) month.`,
      },
      loginData: {
        title: '4.2 Login data',
        description:
          'Certain login data is retained in order to prevent intrusion, and for the purposes of legal proceedings should the need arise. This data is retained for twelve (12) months.',
      },
    },
    undertaking: {
      title: '5. UNDERTAKING REGARDING THE USE OF THE PORTAL AND ITS CONTENT',
      description: `The user undertakes not to commit any illicit or fraudulent act on the Portal.<br/><br/>
        These Terms of Use prohibit any act which could tarnish the name and/or the image of the State of Monaco, or damage, overload, disable, discredit or compromise MonGuichet.mc Portal.<br/><br/>
        In particular, the following are prohibited: the transmission by email or any other means of content whose purpose is to persecute, harass, defame, discriminate or abuse; or any other dissemination of information or opinion of a personal nature relating to one or more individuals, or infringing human rights, or causing harm to minors.<br/><br/>
        Correspondence must be courteous in nature at all times and comply with the basic rules of politeness.`,
    },
    security: {
      title: '6. WEBSITE SECURITY',
      description: `The Portal requires a sufficiently fast internet connection and equipment and material resources enabling Users to access and browse on the Portal. The browser must be configured to allow session cookies.<br/><br/>
        The User is aware of the risks associated with using the internet and accepts the corresponding limitations and risks.<br/><br/>
        The Administration makes every effort to secure the MonGuichet.mc Portal regard to the risks involved and the nature of the data which is processed.<br/><br/>
        The User is prohibited from fraudulently accessing or continuing to visit all or part of the Portal, deleting or changing the information included on the Portal, fraudulently adding information, altering the Portal, or interfering with its proper functioning.<br/><br/>
        The User undertakes not to attempt to modify or manipulate the Portal pages in such a way as to conceal, misappropriate or alter them.<br/><br/>
        It is strictly prohibited to: (i) reproduce, modify, adapt or alter the Portal and/or its contents; (ii) access or seek to access the Portal’s source code using decompilation, reverse engineering or any other technique or method; (iii) make accessible or send parts of the Portal or content to which access is restricted to third parties; (iv) use the Portal for an unlawful purpose, to which end the User undertakes not to include any false, incomplete or inaccurate information, or any virus, Trojan horse, worm, time bomb or any other program designed to damage, produce a harmful effect, intercept or block any system, data or personal information.<br/><br/>
        Where necessary, the User must have a secure connection or network and an up-to-date operating system to protect, as far as possible, the Administration from any damage relating to insecure use of the Portal.<br/><br/>
        The User shall take all appropriate measures to protect their data and equipment, and they are responsible for the security of their data and network, which they use at their own risk.<br/><br/>
        In the event that the User is aware of an event that could damage the security of the Portal, such as an error, fault, malfunction, hacking, loss of data, vulnerability or irregularities, they are invited to get in touch with the Administration immediately by emailing: <a class="text-interaction underline" href="mailto:contact{'@'}monguichet.mc">contact{'@'}monguichet.mc</a>.`,
    },
    intellectualProperty: {
      title: '7. INTELLECTUAL PROPERTY',
      description:
        'These Terms of Use do not imply any transfer of intellectual property rights of any kind to the User.',
      copyright: {
        title: '7.1 Copyright',
        description: `All of the rights associated with any content published on MonGuichet.mc belong exclusively to the Administration where it is explicitly indicated that the rights belong to a third party. This includes, but is not limited to, all texts, comments, titles, names, photographs, sounds, images, data, drawings, animated sequences with sound, services and graphics which are protected by the legislation on copyright protection in force in Principality of Monaco.<br/><br/>
        Any use, representation, transformation, reproduction, adaptation or dissemination, in whole or in part, of any element or content from MonGuichet.mc on any media and via any procedure is prohibited and constitutes a criminal offence that will be punished by the Monaco courts, unless express permission is obtained from the Administration.`,
      },
      trademarks: {
        title: '7.2 Trademarks',
        description: `Trademarks and/or logos belonging to the Administration and appearing on this Portal are protected trademarks under the legislation that applies in Monaco. Any reproduction of these trademarks and/or logos, in whole or in part, without the permission of the owner, is a criminal offence punishable by the Monaco courts.`,
      },
    },
    liability: {
      title: '8. LIABILITY',
      description: `The Portal must not be used in an abusive or malicious way. The Administration cannot be held liable towards the User for damages of any kind, direct or indirect, resulting from the use of the Portal, nor from its unavailability.<br/><br/>
      Generally speaking, the User undertakes to use the Services:
            <ul class="list-disc list-inside">
              <li>In compliance with the laws and regulations of Monaco, the rights of third parties, and intellectual property rights;</li>
              <li>Fairly and in accordance with their intended purpose;</li>
              <li>At their own risk.</li>
            </ul>
        <br/>
        The User acknowledges and accepts that the Administration may not be held liable in respect of the Portal, particularly in the event of hacking, tampering with or misappropriation of data or the network, or any use that is unlawful or prejudicial to the User or a third party.<br/><br/>
        The Administration shall endeavour to ensure that the information published on this Portal remains accurate and up to date, but accepts no responsibility concerning the use of incorrect or outdated information.<br/><br/>
        The Administration does not guarantee the continuity, accessibility or availability of the Portal or related Services.<br/><br/>
        The User of the Portal is required to comply with these Terms of use and are prohibited from using or attempting to use the Portal and its services for any purpose other than those provided for under these terms of use.`,
    },
    protectionPersonalData: {
      title: '9. PROTECTION OF PERSONAL DATA',
      description: `In accordance with the applicable provisions relating to the protection of personal data in the Principality of Monaco, the information gathered via MonGuichet.mc is collected by the Digital Services Department of the State of Monaco, acting in its capacity as the data controller and operates a processing system for the purpose of "Account management, enabling Users to initiate and follow up procedures and appointments via online services".<br/><br/>
        The process is justified by:
            <ul class="list-disc list-inside">
              <li>The consent of the individual concerned;</li>
              <li>Pursuit of a legitimate interest on the part of the Administration Sovereign Ordinance no. 3.413 of 29 August 2011 concerning various measures pertaining to the relationship between the Administration and its subjects (improving access, use and follow-up of online service procedures for users, follow-up of appointments booked online by users).</li>
            </ul><br/>
            Information processed as part of the MonGuichet.mc's Services is for the Administration and is never used for commercial or advertising communications.<br/><br/>
            The information processed is retained for the following periods:
            <ul class="list-disc list-inside">
              <li>Information relating to the identity, address, user name, password and nature of the Account is kept for three (3) years from the User’s last log-in;</li>
              <li>The documents that you choose to store in your document folder are kept for as long as the account is active;</li>
              <li>Information relating to browsing history, IP address and log-in data is kept for three (3) months;</li>
              <li>Information required for cookies is kept for a maximum of thirteen (13) months.</li>
            </ul><br/>
        The information requested when creating a Personal Account is mandatory (for example, an email address is required to validate the Personal Account).<br/><br/>
        The Administration will not be able to process the User's request if the mandatory fields (marked with an *) on the form are incomplete.<br/><br/>
        In accordance with the legislation that applies in the Principality of Monaco, the User has the right of access to their personal data; the right to object to processing as well as the right to request that inaccurate, incomplete or outdated data to be corrected, updated or deleted.<br/><br/>
        The User should also be aware that they may withdraw their consent at any time.<br/><br/>
        To exercise their rights or in the event of any questions about how Personal Data is processed in connection with MonGuichet.mc, the User may make a written request, to the following email address: <a class="text-interaction underline" href="mailto:mesdonnees{'@'}gouv.mc" target="_blank">mesdonnees{'@'}gouv.mc</a>, or by post to Direction des Services Numériques, Immeuble Les industries, 2 Rue du Gabian, MC 98000 MONACO, stating the subject of the request and including their surname, first name, and email address.<br/><br/>
        The User may also submit a request to the Administration by clicking <a class="text-interaction underline" href="https://contacts.gouv.mc/en/contactez-monguichet" target="_blank">here</a>.<br/><br/>
        To ensure that the response remains confidential and that we are replying only to the person whose data is involved, those submitting requests may be asked to provide proof of their identity, in black and white.<br/><br/>
        Individuals who have exercised their rights but feel, after contacting the Administration, that their rights have not been respected, can submit a complaint to the Data Protection Authority of Monaco (Commission de Contrôle des Informations Nominatives - CCINN): <a class="text-interaction underline" href="https://www.ccin.mc/en/" target="_blank">www.ccin.mc</a>.`,
    },
    favorableOpinion: {
      title: '10. APPROVAL FROM THE DATA PROTECTION AUTHORITY',
      description: `This Portal named "MonGuichet.mc" has been favourably received by the Data Protection Authority, by Deliberation 2022-63 of 20 April 2022. It was the subject of a decision made on 20 April 2022 of H.E. the Minister of State, relating to the implementation, by the Digital Services Department, of account management enabling users to undertake and monitor procedures via online services, published in the <a class="text-interaction underline" href="https://journaldemonaco.gouv.mc/Journaux/2022/Journal-8589" target="_blank">Journal de Monaco</a> on 6 May 2022.`,
    },
    falseStatement: {
      title: '11. FALSE STATEMENT',
      description: `The User hereby declares that they are fully aware of the penalties set out in Article 90 onwards of the Monegasque Criminal Code that will be applied in the event of a false statement, i.e. a prison sentence of between three (3) months and one (1) year and/or a fine as stipulated in paragraph 2 of Article 26.`,
    },
    hyperlinks: {
      title: '12. HYPERLINKS',
      description: `The creation of hyperlinks to MonGuichet.mc is not subject to prior authorisation from the Administration and is authorised for any medium, with the exception of those disseminating information of a controversial, pornographic or xenophobic nature, or which may, more broadly, offend a large number of people. Explicit reference to the State of Monaco in the link title is desirable. The Administration reserves the right to request removal of all existing links which contravene the purpose of MonGuichet.mc.<br/><br/>
      MonGuichet.mc may contain hyperlinks to external or third-party websites. The Administration does not control these websites and may not under any circumstances be held liable in any way for the content published on these external websites.<br/><br/>
      The Administration may not be held responsible for any damage or losses that may result from or be connected with the use of these external websites. Users of the Portal bear full responsibility for and assume the risks of using these external websites. The Administration may not be held liable in any way.<br/><br/>
      All Users of MonGuichet.mc are invited to contact the Administration <a class="text-interaction underline" href="https://contacts.gouv.mc/en/contactez-monguichet" target="_blank">here</a> if they encounter a link which does not work.`,
    },
    competentJurisdiction: {
      title: '13. COMPETENT JURISDICTION',
      description: `These Terms of Use are governed by Monegasque law.<br/><br/>
      If an amicable settlement cannot be reached, any disputes relating to the validity, interpretation or execution of these Terms of Use shall be referred to the competent courts in the Principality of Monaco, notwithstanding a plurality of defendants.`,
    },
  },
  legalNotice: {
    linkTitle: 'Legal Notices',
    title: 'Legal Notices',
    description:
      'This Portal, hereinafter referred to as “MonGuichet.mc” is published by the <strong class="font-semibold">State of Monaco</strong>, hereinafter referred to as “the Administration”, which has its head office at 2, rue du Gabian, MC 98000 MONACO.',
    publisherLabel: 'Publisher',
    publisher:
      'Secrétariat Général du Ministère d’Etat<br/>(General Secretariat of the Ministry of State)</br>Place de la visitation</br>MC 98000 MONACO',
    directorOfPublicationTitle: 'DIRECTOR OF PUBLICATION',
    directorOfPublicationBreakdown:
      "Direction des Services Numériques<br/>(Digital Services Department)</br>Mr. Julien DEJANOVIC</br>2 rue du Gabian</br>Immeuble 'Les industries'</br>MC 98000 MONACO",
    contactLabel: 'Contact',
    contact:
      "Phone: (+377) 98 98 40 26</br>Postal address : 2 rue du Gabian, Immeuble 'Les Industries', BP 673, MC 98014 MONACO CEDEX</br>Contact form: <a class='text-interaction underline' href='https://contacts.gouv.mc/en/contactez-monguichet' target='_blank'>https://contacts.gouv.mc/en/contactez-monguichet</a>",
    hostingLabel: 'Hosting',
    hosting:
      'MonGuichet.mc is hosted by the Monaco Telecom, a company with a share capital of EUR 1,687,640.00, listed in the Monaco Trade and Industry Registry under the number 97 S 03277, which has its registered office at 4-6 avenue Albert II – 98000 MONACO.',
  },
  cookies: {
    linkTitle: 'Cookies',
    title: 'Cookies Policy',
    description: `“Cookies” are files of information, generally small in size, which are identified by a name and may be sent to your browser by a website that you are visiting. They record information about your browsing of a website.<br/><br/>
    The Portal uses cookies to ensure the availability and correct functioning of this Portal in order to optimise the browsing experience.`,
    necessary: {
      title: `1. Cookies absolutely necessary to the operation of "MonGuichet.mc" Portal`,
      description: `These cookies are essential for browsing the Portal.<br/><br/>
      They enable continuity of the main services provided by the Portal and secure the User’s connection.<br/><br/>
      The table below summarises all of the information about the various cookies used on the "MonGuichet.mc" Portal which are absolutely necessary for its operation.`,
      cookieName: `Name of the cookies`,
      service: `Service`,
      purpose: 'Purpose',
      validity: `Storage time`,
      storageTime: 'Deleted when you leave the browser session.',
      'XSRF-TOKEN': {
        service: 'Cookie for the CSRF protection of the FRONT',
        purpose: 'Protection against “cross site request forgery”',
      },
      _csrf: {
        service: 'Cookie for the CSRF protection of the FRONT',
        purpose: 'Protection against “cross site request forgery”',
      },
      refreshToken: {
        service: 'Keycloak Cookie',
        purpose:
          'Cookies containing a token allowing the refreshment of the token',
      },
      refreshTokenExpiration: {
        service: 'Keycloak Cookie',
        purpose:
          'Set the expiration time of the refresh token provided by Keycloak',
      },
      tokenKeycloak: {
        service: 'Keycloak Cookie',
        purpose: 'Token provided by Keycloak for authentication',
      },
      tokenExpiration: {
        service: 'Keycloak Cookie',
        purpose:
          'Set the expiration time of the refresh token provided by Keycloak',
      },
      strategy: {
        service: 'Keycloak Cookie',
        purpose:
          'Allows Keycloak to understand what type of authentication is used',
      },
      i18n_redirected: {
        service: 'Cookie i18n (translation and language management library)',
        purpose:
          'Allows the browser to align the language of the browser and the language of MyGuichet.mc. Also allows to manage the languages within MyGuichet.mc. Also allows translation on MyGuichet.mc.',
      },
    },
    analytics: {
      title: '2. Analytics cookies',
      description: `Analytics or statistics cookies may be installed in order to improve our services. These cookies are used to compile statistics on the number of visitors to the website, for example, and how the various parts of MonGuichet.mc are used (which sections and content users visit, how they got there) so that we can make it more interesting and easier to navigate.<br/>
      In the case of MonGuichet.mc, the analytics cookies placed on the internet user’s terminal produce anonymised statistics. The final two digits of the internet user’s IP address are anonymised.`,
      cookiesName: `Start with:
        <ul class="list-disc list-inside">
         <li>_pk_ref</li>
         <li>_pk_cvar</li>
         <li>_pk_id</li>
         <li>_pk_ses</li>
        </ul>`,
      service: 'Matomo',
      purpose: `The purpose of this tool is to measure the audience of the portal (IP address (with anonymization of the last 2 bytes): pages visited and their number, number of times each page is displayed, time spent on each page, number of clicks, name and version of the user's web browser, the user's operating system, time stamp of access, number and name of documents downloaded.`,
      storageTime: '13 months',
    },
    managing: {
      title: '3. Managing cookies',
      description: `Users are free to manage cookies according to their own preferences, and it is their responsibility to do so. Some parts of the Portal may no longer work properly if certain cookies have been disabled. The Administration cannot be held liable for this.<br/><br/>
      The User may change their preferences and consent with regard to the cookies sent to their device at any time by changing their browser settings, as explained below.<br/><br/>
      The User should also note that they can take the following steps:`,
      deletion:
        '<strong>Deleting cookies</strong>: it is possible to delete all cookies that have already been placed on your device by clearing your browser history. If you do this, all the cookies from the various websites you have visited will be deleted and saved information (login details, for example) may be lost',
      gestion:
        '<strong>Cookie management</strong>: you should consult your browser’s cookie management and privacy settings. You can change your browser settings to manage or block cookies at any time. For more information, please refer to your browser’s cookie management policy:',
      firefox: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences" target="_blank">Firefox</a>`,
      safari: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="https://support.apple.com/fr-fr/HT201265" target="_blank">Safari</a>`,
      chrome: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en" target="_blank">Chrome</a>`,
      opera: `<span class="mr-3 h-1.5 w-1.5 rounded flex border border-black"></span><a class="text-interaction underline" href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank">Opera</a>`,
      ccin: '<a class="text-interaction underline" href="https://www.ccin.mc/en/fact-sheets/bonnes-pratiques-en-matiere-de-sites-internet/" target="_blank">CCIN</a>',
      cnil: '<a class="text-interaction underline" href="https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur" target="_blank">CNIL</a>',
      complementaryInfos:
        'The following pages provide additional information about cookies:',
    },
  },
  contact: {
    title: 'Contact',
    link: 'https://contacts.gouv.mc/en/contact-monguichet',
  },
  register: {
    emailAlreadyExistsText:
      'The contact email address {email} is already associated with an existing account',
    explanationFirst: `We have identified that the contact email address <b>{email}</b> is already associated with one or more accounts.`,
    explanationSecond:
      'To see your previous services, please log in using your existing account.',
    connect: 'Log in',
    findIdentifier: 'Find your username',
    ignore: 'Ignore, continue to create account',
  },
}
